var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: { classes: "new-email", visible: _vm.isModalVisible },
      on: {
        closeModal: function ($event) {
          return _vm.$emit("closeModal")
        },
      },
    },
    [
      _c("template", { slot: "header" }, [
        !_vm.action
          ? _c("div", { staticClass: "name" }, [_vm._v("NEUE EMAIL")])
          : _vm.action === "reply"
          ? _c("div", { staticClass: "name" }, [_vm._v("Antworten")])
          : _vm.action === "replyAll"
          ? _c("div", { staticClass: "name" }, [_vm._v("Allen Antworten")])
          : _vm.action === "forward"
          ? _c("div", { staticClass: "name" }, [_vm._v("Weiterleiten")])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "buttons has-addons" }, [
          _c(
            "button",
            {
              staticClass: "button is-large",
              class: { "is-primary": !_vm.hasClients },
              on: {
                click: function ($event) {
                  _vm.hasClients = false
                },
              },
            },
            [
              _c("i", { staticClass: "material-icons" }, [_vm._v("email")]),
              _vm._v(" Standardmitteilung"),
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "button is-large",
              class: { "is-primary": _vm.hasClients },
              on: {
                click: function ($event) {
                  _vm.hasClients = true
                },
              },
            },
            [
              _c("i", { staticClass: "material-icons" }, [_vm._v("dehaze")]),
              _vm._v(" Leistungsbezogene Mitteilung"),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "content" },
        [
          _c("orders-select", {
            attrs: {
              hasClients: _vm.hasClients,
              preselectedOrders: _vm.preselectedOrders,
              preselectedClient: _vm.preselectedRequest,
              params: { _groups: ["client_read"] },
            },
            on: {
              updateOrders: (value) => (_vm.ordersSelected = value),
              update: _vm.handleUpdate,
            },
          }),
          _vm._v(" "),
          _c("email-form", {
            ref: "form",
            attrs: {
              ordersSelected: _vm.ordersSelected,
              hasClients: _vm.hasClients,
              recipient: _vm.recipient,
              emailSelected: _vm.emailAction ? _vm.emailSelected : null,
              preselectedContact: _vm.preselectedContact,
              "select-template": _vm.selectTemplate,
              "attach-document": _vm.attachDocument,
            },
            on: { closeModal: (refresh) => _vm.$emit("closeModal", refresh) },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }