var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      staticClass: "is-form",
      attrs: { visible: _vm.isVisible },
      on: {
        closeModal: function ($event) {
          _vm.isVisible = false
        },
      },
    },
    [
      _c("p", { attrs: { slot: "header" }, slot: "header" }, [
        _vm._v("Dokument hochladen"),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "content" }, slot: "content" }, [
        _c("table", [
          !_vm.multiple
            ? _c("tr", [
                _c("th", [
                  _c("label", { staticClass: "label" }, [
                    _vm._v("Dokumentenname"),
                  ]),
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("div", { staticClass: "control" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.name,
                          expression: "form.name",
                        },
                      ],
                      staticClass: "input",
                      attrs: { type: "text" },
                      domProps: { value: _vm.form.name },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.form, "name", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c("label", { staticClass: "label" }, [_vm._v("Kategorie")]),
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("multiselect", {
                    attrs: {
                      options: _vm.categories,
                      "track-by": "key",
                      label: "value",
                    },
                    model: {
                      value: _vm.category,
                      callback: function ($$v) {
                        _vm.category = $$v
                      },
                      expression: "category",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c("label", { staticClass: "label" }, [_vm._v("Zuordnung")]),
            ]),
            _vm._v(" "),
            _c("td", [
              _c("div", { staticClass: "control" }, [
                _c("div", { staticClass: "select" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.assignment,
                          expression: "form.assignment",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.form,
                            "assignment",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "client" } }, [
                        _vm._v("reiseunbezogen"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "order" } }, [
                        _vm._v("reisebezogen"),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [_c("td", { attrs: { colspan: "2" } }, [_c("hr")])]),
          _vm._v(" "),
          _vm.form.assignment === "order"
            ? _c("tr", [
                _c("th", [
                  _c("label", { staticClass: "label", attrs: { for: "" } }, [
                    _vm._v("Reise"),
                  ]),
                ]),
                _vm._v(" "),
                _c("td", [
                  _c(
                    "div",
                    { staticClass: "control" },
                    [
                      _c(
                        "multiselect",
                        {
                          attrs: {
                            options: _vm.options.orders,
                            loading: _vm.isOrderLoading,
                            "track-by": "id",
                            "custom-label": _vm.customLabel,
                            "internal-search": false,
                            "clear-on-select": false,
                            multiple: "",
                          },
                          on: { "search-change": _vm.searchOrder },
                          model: {
                            value: _vm.form.links.order,
                            callback: function ($$v) {
                              _vm.$set(_vm.form.links, "order", $$v)
                            },
                            expression: "form.links.order",
                          },
                        },
                        [
                          _c("span", {
                            attrs: { slot: "caret" },
                            slot: "caret",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ])
            : _c("tr", [
                _c("th", [
                  _c("label", { staticClass: "label" }, [_vm._v("Kunde")]),
                ]),
                _vm._v(" "),
                _c("td", [
                  _c(
                    "div",
                    { staticClass: "control" },
                    [
                      _c(
                        "multiselect",
                        {
                          attrs: {
                            options: _vm.options.clients,
                            loading: _vm.isClientLoading,
                            "track-by": "id",
                            label: "companyName",
                            "internal-search": false,
                            "clear-on-select": false,
                            multiple: "",
                          },
                          on: { "search-change": _vm.searchClient },
                          model: {
                            value: _vm.form.links.client,
                            callback: function ($$v) {
                              _vm.$set(_vm.form.links, "client", $$v)
                            },
                            expression: "form.links.client",
                          },
                        },
                        [
                          _c("span", {
                            attrs: { slot: "caret" },
                            slot: "caret",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c("label", { staticClass: "label", attrs: { for: "" } }, [
                _vm._v("Reisekonzept"),
              ]),
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c(
                    "multiselect",
                    {
                      attrs: {
                        options: _vm.options.orderConcepts,
                        loading: _vm.isOrderConceptLoading,
                        "track-by": "id",
                        label: "name",
                        "internal-search": false,
                        "clear-on-select": false,
                        multiple: "",
                      },
                      on: { "search-change": _vm.searchOrderConcept },
                      model: {
                        value: _vm.form.links.orderConcept,
                        callback: function ($$v) {
                          _vm.$set(_vm.form.links, "orderConcept", $$v)
                        },
                        expression: "form.links.orderConcept",
                      },
                    },
                    [_c("span", { attrs: { slot: "caret" }, slot: "caret" })]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c("label", { staticClass: "label", attrs: { for: "" } }, [
                _vm._v("Tageskonzept"),
              ]),
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c(
                    "multiselect",
                    {
                      attrs: {
                        options: _vm.options.dayConcepts,
                        loading: _vm.isDayConceptLoading,
                        "track-by": "id",
                        label: "name",
                        "internal-search": false,
                        "clear-on-select": false,
                        multiple: "",
                      },
                      on: { "search-change": _vm.searchDayConcept },
                      model: {
                        value: _vm.form.links.dayConcept,
                        callback: function ($$v) {
                          _vm.$set(_vm.form.links, "dayConcept", $$v)
                        },
                        expression: "form.links.dayConcept",
                      },
                    },
                    [_c("span", { attrs: { slot: "caret" }, slot: "caret" })]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c("label", { staticClass: "label", attrs: { for: "" } }, [
                _vm._v("Routenstichpunkt"),
              ]),
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c(
                    "multiselect",
                    {
                      attrs: {
                        options: _vm.options.predefinedRouteDetails,
                        loading: _vm.isPredefinedRouteDetailLoading,
                        "track-by": "id",
                        label: "routeDescription",
                        "internal-search": false,
                        "clear-on-select": false,
                        multiple: "",
                      },
                      on: { "search-change": _vm.searchPredefinedRouteDetail },
                      model: {
                        value: _vm.form.links.predefinedRouteDetail,
                        callback: function ($$v) {
                          _vm.$set(_vm.form.links, "predefinedRouteDetail", $$v)
                        },
                        expression: "form.links.predefinedRouteDetail",
                      },
                    },
                    [_c("span", { attrs: { slot: "caret" }, slot: "caret" })]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c("label", { staticClass: "label", attrs: { for: "" } }, [
                _vm._v("Agentur"),
              ]),
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c(
                    "multiselect",
                    {
                      attrs: {
                        options: _vm.options.agencies,
                        loading: _vm.isAgencyLoading,
                        "track-by": "id",
                        label: "name",
                        "internal-search": false,
                        "clear-on-select": false,
                        multiple: "",
                      },
                      on: { "search-change": _vm.searchAgency },
                      model: {
                        value: _vm.form.links.agency,
                        callback: function ($$v) {
                          _vm.$set(_vm.form.links, "agency", $$v)
                        },
                        expression: "form.links.agency",
                      },
                    },
                    [_c("span", { attrs: { slot: "caret" }, slot: "caret" })]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c("label", { staticClass: "label", attrs: { for: "" } }, [
                _vm._v("Region"),
              ]),
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("area-select", {
                    attrs: { multiple: "" },
                    model: {
                      value: _vm.form.links.area,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.links, "area", $$v)
                      },
                      expression: "form.links.area",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c("label", { staticClass: "label", attrs: { for: "" } }, [
                _vm._v("Leistungsträger"),
              ]),
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c(
                    "multiselect",
                    {
                      attrs: {
                        options: _vm.options.providers,
                        loading: _vm.isProviderLoading,
                        "track-by": "id",
                        label: "name",
                        "internal-search": false,
                        "clear-on-select": false,
                        multiple: "",
                      },
                      on: { "search-change": _vm.searchProvider },
                      model: {
                        value: _vm.form.links.provider,
                        callback: function ($$v) {
                          _vm.$set(_vm.form.links, "provider", $$v)
                        },
                        expression: "form.links.provider",
                      },
                    },
                    [_c("span", { attrs: { slot: "caret" }, slot: "caret" })]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c("label", { staticClass: "label", attrs: { for: "" } }, [
                _vm._v("Ort"),
              ]),
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("place-select", {
                    attrs: { multiple: "" },
                    model: {
                      value: _vm.form.links.place,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.links, "place", $$v)
                      },
                      expression: "form.links.place",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c("label", { staticClass: "label", attrs: { for: "" } }, [
                _vm._v("Destination"),
              ]),
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("destination-select", {
                    attrs: { multiple: "" },
                    model: {
                      value: _vm.form.links.destination,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.links, "destination", $$v)
                      },
                      expression: "form.links.destination",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c("label", { staticClass: "label", attrs: { for: "" } }, [
                _vm._v("Unternehmen"),
              ]),
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c(
                    "multiselect",
                    {
                      attrs: {
                        options: _vm.options.organisations,
                        loading: _vm.isOrganisationLoading,
                        "track-by": "id",
                        label: "name",
                        "internal-search": false,
                        "clear-on-select": false,
                        multiple: "",
                      },
                      on: { "search-change": _vm.searchOrganisation },
                      model: {
                        value: _vm.form.links.organisation,
                        callback: function ($$v) {
                          _vm.$set(_vm.form.links, "organisation", $$v)
                        },
                        expression: "form.links.organisation",
                      },
                    },
                    [_c("span", { attrs: { slot: "caret" }, slot: "caret" })]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c("label", { staticClass: "label", attrs: { for: "" } }, [
                _vm._v("Bank"),
              ]),
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c(
                    "multiselect",
                    {
                      attrs: {
                        options: _vm.options.banks,
                        loading: _vm.isBankLoading,
                        "track-by": "id",
                        label: "name",
                        "internal-search": false,
                        "clear-on-select": false,
                        multiple: "",
                      },
                      on: { "search-change": _vm.searchBank },
                      model: {
                        value: _vm.form.links.bank,
                        callback: function ($$v) {
                          _vm.$set(_vm.form.links, "bank", $$v)
                        },
                        expression: "form.links.bank",
                      },
                    },
                    [_c("span", { attrs: { slot: "caret" }, slot: "caret" })]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "level", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "button",
            {
              staticClass: "button",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.cancelModal.apply(null, arguments)
                },
              },
            },
            [_vm._v("Abbrechen")]
          ),
          _vm._v(" "),
          _vm.has(_vm.form, "id")
            ? _c(
                "button",
                {
                  staticClass: "button is-success",
                  class: { "is-loading": _vm.isLoading },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.save.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("Speichern")]
              )
            : [
                _c("input", {
                  ref: "fileUpload",
                  staticClass: "is-hidden",
                  attrs: { type: "file" },
                  on: { change: _vm.save },
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "button is-success",
                    class: { "is-loading": _vm.isLoading },
                    on: {
                      click: function ($event) {
                        return _vm.$refs.fileUpload.click()
                      },
                    },
                  },
                  [_vm._v("Dokument auswählen und speichern")]
                ),
              ],
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }