var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("popover", { attrs: { trigger: "hover", delay: 20 } }, [
    _c("div", { on: { mouseover: _vm.loadData } }, [_vm._t("default")], 2),
    _vm._v(" "),
    _c("div", { attrs: { slot: "popover" }, slot: "popover" }, [
      _c(
        "div",
        { staticClass: "level", staticStyle: { width: "200px" } },
        [
          !_vm.dataReady
            ? [_c("span", [_vm._v("Daten werden geladen...")])]
            : _vm.updateData.updatedAt
            ? [
                _vm.has(_vm.updateData.updatedBy, "username")
                  ? _c("user-image", {
                      staticClass: "level-item",
                      attrs: {
                        user: _vm.updateData.updatedBy,
                        editable: "",
                        small: "",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("span", { staticClass: "level-item" }, [
                  _vm._v(
                    "\n                Aktualisiert am " +
                      _vm._s(_vm.updateData.updatedAt.substring(0, 10)) +
                      "\n                "
                  ),
                ]),
              ]
            : _c("span", [_vm._v("Keine Daten verfügbar")]),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }