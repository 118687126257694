var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return this.value
    ? _c(
        "modal",
        {
          attrs: { visible: "" },
          on: {
            closeModal: function ($event) {
              return _vm.$emit("input", null)
            },
          },
        },
        [
          _c("p", { attrs: { slot: "header" }, slot: "header" }, [
            _vm._v("Vorschau"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.value.cloudStorage
                ? _c("microsoft-frame", { attrs: { value: _vm.value } })
                : _c("my-frame", { attrs: { value: _vm.value } }),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }