<template>
    <popover trigger="hover" v-bind:delay="20">
        <div @mouseover="loadData">
            <slot></slot>
        </div>
        <div slot="popover">
            <div class="level" style="width: 200px">
                <template v-if="!dataReady">
                    <span>Daten werden geladen...</span>
                </template>
                <template v-else-if="updateData.updatedAt">
                    <user-image
                        class="level-item"
                        v-if="has(updateData.updatedBy, 'username')"
                        v-bind:user="updateData.updatedBy"
                        editable
                        small
                    />
                    <span class="level-item">
                    Aktualisiert am {{updateData.updatedAt.substring(0, 10)}}
                    </span>
                </template>
                    <span v-else>Keine Daten verfügbar</span>
            </div>
        </div>
    </popover>
</template>

<script>
import Popover from "@components/Popover";
import UserImage  from '@components/UserImage';
import has      from 'lodash/has';
import moment   from 'moment';
import Special from "@orders/subpages/Offer/Panels/Items/special";
import axios from 'axios';

export default {
    props: {
        update: {
            type: Object,
            default: () => {
                return {}
            }
        },
        api: {
            type: String,
            required: false,
        }
    },
    methods: {
        has,
        moment,

        loadData: function() {
            if(!this.apiWasCalled){
                this.apiWasCalled = true;
                axios.get(this.api, {params: {
                        '_groups': ['meta_read', 'user_read']
                    }}).then(response => {
                        this.dataReady = true;
                        this.updateData = {
                            updatedAt: response.data.updatedAt,
                            updatedBy: response.data.updatedBy,
                        }
                })
            }
        }
    },

    data: function() {
        return {
            dataReady: !this.api,
            apiWasCalled: false,
            updateData: {
                updatedAt: this.update.updatedAt,
                updatedBy: this.update.updatedBy,
            }
        }
    },


    components: {
        Special,
        Popover,
        UserImage
    },
}
</script>
