<template>
    <div class="buttons">
        <div class="buttons has-addons is-days toggle-switch">
            <template v-if="picker">
                <button
                    v-for="(day, n) in days"
                    v-bind:key="n"
                    class="button is-small"
                    v-bind:class="{'is-primary' : includes(innerValue, n)}"
                    v-on:click.stop.prevent="handleDayClick(n)"
                >{{day}}</button>
            </template>
            <template v-else>
                <div
                    v-for="(day, n) in days"
                    v-bind:key="n"
                    class="button is-small is-read-only"
                    v-bind:class="{'is-primary' : includes(innerValue, n)}"
                >{{days[n]}}</div>
            </template>
        </div>
    </div>
</template>

<script>
import includes from 'lodash/includes';

export default {
    props: {
        value: {
            type: Array
        },

        picker: {
            type: Boolean,
            default: false
        }
    },

    data: function () {
        return {
            days: ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'],
            innerValue: this.value
        }
    },

    methods: {
        includes,

        handleDayClick: function (index) {
            if (!includes(this.innerValue, index)) {
                this.innerValue.push(index)
            } else {
                this.innerValue = this.innerValue.filter(v => v !== index);
            }

            this.$emit('input', this.innerValue);
        }
    },

    watch: {
        value: function (newValue) {
            if (newValue.join('') !== this.value.join(',')) {
                this.innerValue = newValue
            }
        }
    }
}
</script>
