<template>
    <div class="requests-table">
        <label>Leistungen:</label>
        <div class="redesign-table">
            <header>
                <div><input type="checkbox" v-model="checkAll" /></div>
                <div>Leistung</div>
                <div>Datum</div>
                <div>Status</div>
                <div>Details</div>
                <div>Deadline</div>
            </header>

            <main>
                <template v-for="orderID in orderIDs">
                    <div class="row is-headline"># {{ orderID }}</div>
                    <div
                        v-for      = "request in sortedRequests(orderID)"
                        v-bind:key = "request.id"
                    >
                        <div><input type="checkbox" v-bind:checked="isChecked(request)" v-on:change="check(request)" /></div>
                        <div>
                            <i v-if="request.requestType === 'hotel'" class="material-icons" title="Hotel">home</i>
                            <i v-else-if="request.requestType === 'ferry'" class="material-icons" title="Fähre">directions_boat</i>
                            <i v-else-if="request.requestType === 'airline'" class="material-icons" title="Flug">airplanemode_active</i>
                            <i v-else-if="request.requestType === 'train'" class="material-icons" title="Zug">directions_train</i>
                            <other-service-icons v-else v-bind:type="request.otherServiceType.category"></other-service-icons>
                            {{ request.info.title }}
                        </div>
                        <div>{{ request.info.startAt | dayMonth }}  -  {{ request.info.endAt | fullYear }}</div>

                        <!-- Status -->
                        <div v-if="options">{{ getValueWithKey({ key: request.requestStatus.status, optionList: options.status }) }}</div>
                        <div v-else>request.requestStatus.status</div>

                        <!-- Details -->
                        <div v-if="request.requestType === 'hotel'">
                            <template v-if="options">{{ getValueWithKey({ key: request.board, optionList: options.boardList }) }}</template>
                            <template v-else></template>
                        </div>
                        <div v-else-if="request.requestType === 'ferry'">
                            {{ request.type === 'night' ? 'Nachtfähre' : 'Tagfähre' }}
                        </div>
                        <div v-else></div>

                        <div><div v-if="request.optionTodo && request.optionTodo.dueDate" v-bind:class="['deadline-date', deadlineUrgency(request.optionTodo.dueDate)]"><i class="material-icons">notifications_none</i> {{ request.optionTodo.dueDate | fullYear }}</div></div>
                    </div>
                </template>
            </main>
        </div>
    </div>
</template>



<script>
import uniqBy from 'lodash/uniqBy';
import OtherServiceIcons from '@components/OtherServiceIcons';
import { dayMonth, deadlineUrgency, fullYear, getValueWithKey, compareDates } from '@utilities/functions';


export default {
    props: {
        requests: { type: Array, required: true },
        options:  {}
    },


    computed: {
        orderIDs: function () {
            return uniqBy(this.requests, 'orderID').map(request => request.orderID);
        },


        checkAll: {
            get: function () {
                return this.requests.length === this.requestsSelected.length;
            },

            set: function (value) {
                if (value) {
                    this.requestsSelected = JSON.parse(JSON.stringify(this.requests));

                } else {
                    this.requestsSelected = [];
                }
            }
        },


        preselectedRequest: function () {
            if (this.$store.state.preselectedClient) {
                return this.$store.state.preselectedClient.requests[0];

            } else {
                return null;
            }
        },
    },


    data: function () {
        return {
            requestsSelected: []
        }
    },


    methods: {
        sortedRequests: function (orderID) {
            return [...this.requests]
                .filter(request => request.orderID === orderID)
                .sort((a, b) => {
                    return compareDates(a.startAt, b.startAt) || a._sort - b._sort;
                })
        },

        isChecked: function (request) {
            const index = this.requestsSelected.findIndex(requestItem => requestItem.id === request.id);
            return index !== -1;
        },


        check: function (request) {
            const index = this.requestsSelected.findIndex(requestItem => requestItem.id === request.id);

            if (index !== -1) {
                // it is selected => need to deselect it
                this.requestsSelected.splice(index, 1);

            } else {
                this.requestsSelected.push(request);
            }
        },


        getAsked: function (request) {
            if (request.requestType === 'hotel') {
                return request.contingent.info.doubleRooms.totalAsked +
                       request.contingent.info.singleRooms.totalAsked +
                       request.contingent.info.otherRooms.totalAsked;

            } else if (request.requestType === 'ferry') {
                return request.contingent.info.doubleCabins.totalAsked +
                       request.contingent.info.singleCabins.totalAsked +
                       request.contingent.info.otherCabins.totalAsked;

            } else {
                // Airline or Other
                return request.contingent.askedAmount;
            }
        },


        getReserved: function (request) {
            if (request.requestType === 'hotel') {
                return request.contingent.info.doubleRooms.totalReserved +
                       request.contingent.info.singleRooms.totalReserved +
                       request.contingent.info.otherRooms.totalReserved;

            } else if (request.requestType === 'ferry') {
                return request.contingent.info.doubleCabins.totalReserved +
                       request.contingent.info.singleCabins.totalReserved +
                       request.contingent.info.otherCabins.totalReserved;

            } else {
                // Airline or Other
                return request.contingent.reservedAmount;
            }
        },

        selectAll() {
            this.requestsSelected = JSON.parse(JSON.stringify(this.requests));
        },

        deadlineUrgency,
        getValueWithKey
    },


    watch: {
        requestsSelected: function () { this.$emit('update', this.requestsSelected); },


        preselectedRequest: {
            handler: function () {
                if (this.preselectedRequest) {
                    this.requestsSelected = [JSON.parse(JSON.stringify(this.preselectedRequest))];
                }
            },
            immediate: true
        }
    },


    filters: {
        dayMonth,
        fullYear
    },


    components: {
        OtherServiceIcons
    }
}
</script>
