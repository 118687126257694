<template>

    <div class="new-email-modal-form">
        <h3 v-if="hasClients && recipient">EMAIL {{ recipient.client.name }}</h3>

        <template v-if="hasClients && recipient && recipient.requests ">
            <requests-select
                ref="requests"
                v-bind:requests="recipient.requests"
                v-bind:options="requestOptions"
                v-on:update="updateRequests"
            ></requests-select>

        </template>
        <div class="buttons-wrapper">
            <div class="is-flex" style="position:relative;margin-left: 74%">
                <button
                    class="button is-large"
                    v-on:click.prevent.stop="saveDraft"
                    v-bind:class="{ 'is-loading' : isLoading.draft }"
                    style="margin: 10px"
                >Outlook Entwurf erstellen</button>
                <div>
                    <button
                        class="button is-primary is-large"
                        v-bind:class="{ 'is-loading' : isLoading.send }"
                        v-on:click="send('close')"
                        style="margin: 10px"
                    ><i class="material-icons">check</i> Email senden</button>

                    <!--                <button
                                        class="button is-primary is-large"
                                        v-bind:class="{ 'is-loading' : isLoading.send }"
                                        v-on:click="send('close')"
                                    ><i class="material-icons">keyboard_arrow_right</i>Senden und Schließen</button>-->
                </div>

            </div>
        </div>
        <div class="input-wrapper">
            <label>Von:</label>
            <email-select v-model="fromEmail" v-on:setOrganisation="changeOrganisation"/>
        </div>

        <div class="input-wrapper">
            <label>An:</label>
            <multiselect
                v-model         = "newEmail.toAddresses"
                v-bind:options  = "toContacts"
                v-bind:multiple = "true"
                v-bind:taggable = "true"
                v-bind:loading  = "isLoading.toContacts"
                v-bind:internal-search = "false"
                label           = "email"
                track-by        = "email"
                placeholder     = ""
                v-on:tag        = "item => addContact(item, 'to')"
                v-on:open          = "getContacts('', 'to')"
                v-on:search-change = "search => getContacts(search, 'to')"
                v-on:select        = "selectContact('to')"
                v-on:close         = "tryContactPreselect('to')"
            >
                <template slot="option" slot-scope="props" v-if="props.option.firstName || props.option.email || props.option.lastName" >
                    <span class="option__name" v-if="props.option.firstName"><i class="material-icons">perm_identity</i> {{ props.option.firstName + ' ' + props.option.lastName   + " - " +  props.option.email }}</span>
                    <span class="option__name" v-else><i class="material-icons">perm_identity</i> {{ props.option.email }}</span>

                    <span class="option__type">
                        <template v-if="props.client"><i class="material-icons">business</i> Company: {{ props.option.client.companyName }}</template>
                    </span>
                </template>

                <span slot="caret" v-if="toContacts.length === 0"></span>
            </multiselect>
        </div>

        <div class="input-wrapper">
            <label>CC:</label>
            <multiselect
                v-model         = "newEmail.ccAddresses"
                v-bind:options  = "ccContacts"
                v-bind:multiple = "true"
                v-bind:taggable = "true"
                v-bind:loading  = "isLoading.ccContacts"
                v-bind:internal-search = "false"
                label           = "email"
                track-by        = "email"
                placeholder     = ""
                v-on:tag        = "item => addContact(item, 'cc')"
                v-on:open          = "getContacts('', 'cc')"
                v-on:search-change = "search => getContacts(search, 'cc')"
                v-on:select        = "selectContact('cc')"
                v-on:close         = "tryContactPreselect('cc')"
            >
                <template slot="option" slot-scope="props" v-if="props.option.firstName || props.option.email || props.option.lastName" >
                    <span class="option__name" v-if="props.option.firstName" ><i class="material-icons">perm_identity</i> {{ props.option.firstName + ' ' + props.option.lastName + " - " + props.option.email }}</span>
                    <span class="option__name" v-else><i class="material-icons">perm_identity</i>{{ props.option.email }}</span>

                    <span class="option__type">
                        <template v-if="props.client"><i class="material-icons">business</i> Company: {{ props.option.client.companyName }}</template>
                    </span>
                </template>

                <span slot="caret" v-if="ccContacts.length === 0"></span>
            </multiselect>
        </div>

        <div class="input-wrapper">
            <label>BCC:</label>
            <multiselect
                v-model         = "newEmail.bccAddresses"
                v-bind:options  = "bccContacts"
                v-bind:multiple = "true"
                v-bind:taggable = "true"
                v-bind:loading  = "isLoading.bccContacts"
                v-bind:internal-search = "false"
                label           = "email"
                track-by        = "email"
                placeholder     = ""
                v-on:tag        = "item => addContact(item, 'bcc')"
                v-on:open          = "getContacts('', 'bcc')"
                v-on:search-change = "search => getContacts(search, 'bcc')"
                v-on:select        = "selectContact('bcc')"
                v-on:close         = "tryContactPreselect('bcc')"
            >
                <template slot="option" slot-scope="props" v-if="props.option.firstName || props.option.email || props.option.lastName" >
                    <span class="option__name" v-if="props.option.firstName" ><i class="material-icons">perm_identity</i> {{ props.option.firstName + ' ' + props.option.lastName + " - " + props.option.email }}</span>
                    <span class="option__name" v-else><i class="material-icons">perm_identity</i>{{ props.option.email }}</span>

                    <span class="option__type">
                        <template v-if="props.client"><i class="material-icons">business</i> Company: {{ props.option.client.companyName }}</template>
                    </span>
                </template>

                <span slot="caret" v-if="bccContacts.length === 0"></span>
            </multiselect>
        </div>


        <div class="input-wrapper">
            <label>Vorlage:</label>
            <div>
                <div class="select">
                    <select v-model="templateCategorySelected">
                        <option v-for="category in options" :key="category.key" v-bind:value="category.key">{{ category.value }}</option>
                    </select>
                </div>

                <email-template-select
                    v-model    = "template"
                    :templates-filtered="templatesFiltered"
                    @fillEmail="fillEmail"
                />



                <button v-on:click.prevent.stop="fillEmail" class="button is-large is-icon">
                    <i class="material-icons">loop</i>
                </button>
            </div>
        </div>


        <div class="input-wrapper">
            <label>Betreff:</label>
            <input type="text" class="input" v-model="newEmail.subject" />
        </div>

        <div class="input-wrapper">
            <label>Beschreibung:</label>

            <tag-select
                v-model="tags"
                v-bind:option-tags="optionTags"
                my-class="is-medium"
            />
        </div>

        <div class="input-wrapper" v-if="((hasClients && recipient && recipient.requests) || requestID) && requestOptions && requestOptions.statuses">
            <label>Status ändern:</label>
            <multiselect
                placeholder="nicht ändern"
                v-bind:options="requestOptions.statuses"
                label="value"
                track-by="key"
                v-model="status"
            />
        </div>

        <div class="input-wrapper">
            <label>Neue Aufgabe:</label>
            <input-date v-model="newEmail.todoDate"></input-date>
        </div>
        <div class="attachments">
            <label>Anhänge:</label>
            <attachments v-bind:ordersSelected="ordersSelected" v-model="newEmail._files"></attachments>
            <br>
        </div>

        <div class="pell-wrapper">
            <label>Text:</label>

            <div style="z-index:0">
                <loading v-if="isLoading.preview"></loading>

                <div>
                    <div>
<!--                        <editor v-model="newEmail.textHtml" defaultParagraphSeparator="p style='Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-size:13px;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;line-height:16px;color:#6A6C6F;overflow-wrap:break-word'"/>-->
                        <tiny-mce v-model="newEmail.textHtml" />
                    </div>
                </div>
            </div>
        </div>


        <div class="input-wrapper"></div>
        <div class="buttons-wrapper">
            <div class="is-flex" style="position:relative;margin-left: 74%">
                <button
                    class="button is-large"
                    v-on:click.prevent.stop="saveDraft"
                    v-bind:class="{ 'is-loading' : isLoading.draft }"
                    style="margin: 10px"
                >Outlook Entwurf erstellen</button>
                <div>
                    <button
                        class="button is-primary is-large"
                        v-bind:class="{ 'is-loading' : isLoading.send }"
                        v-on:click="send('close')"
                        style="margin: 10px"
                    ><i class="material-icons">check</i> Email senden</button>

                    <!--                <button
                                        class="button is-primary is-large"
                                        v-bind:class="{ 'is-loading' : isLoading.send }"
                                        v-on:click="send('close')"
                                    ><i class="material-icons">keyboard_arrow_right</i>Senden und Schließen</button>-->
                </div>

            </div>

        </div>
    </div>
</template>



<script>
import axios                from 'axios';
import jsonToFormData       from 'json-form-data';
import debounce             from 'lodash/debounce';
import uniq                 from 'lodash/uniq';
import uniqBy               from 'lodash/uniqBy';
import moment               from 'moment'

import Loading                        from '@components/Loading';
import { TinyMce, Multiselect, InputDate }        from '@components/form';
import { defaultEmailTags } from '@clientSpecific/utilities/defaultValues'


import { notifyError, notifySuccess } from '@components/Notification';
import {getContacts, getUsers, optionsHotelRequests, editUser} from '@api';
import { validateEmail, getValueWithKey, currentUser }              from '@utilities/functions';
import store          from '../../store';
import Attachments    from './Attachments';
import RequestsSelect from './RequestsSelect';
import emailBody      from '@clientSpecific/email/template/EmailBody.js';
import TagSelect from "@components/form/TagSelect";
import EmailTemplateSelect from "@components/form/EmailTemplateSelect";
import EmailSelect from "@components/form/EmailSelect";

export default {
    store: store,
    props: {
        hasClients:         { type: Boolean, default: false },
        recipient:          { type: Object },
        emailSelected:      { default: null },
        initialContacts:    { type: Array, default: () => [] },
        preselectedContact: { default: null },
        selectTemplate :    { default: null },
        ordersSelected:     { type: Array, default: () => [] },
        requestID:          { default: null },
        requestType:        { default: null },
        attachDocument:     { type: Object, default: null}

    },
    computed: {
        action:             function () { return this.$store.state.action; },

        templates:          function () { return this.$store.state.templates; },
        templateCategories: function () { return uniqBy(this.templates.map(template => template.category)); },
        templatesFiltered:  function () { return this.templates.filter(template => template.category === this.templateCategorySelected).sort((a,b) => {return a.position - b.position}); },

        users:              function () { return this.$store.state.users; },

        status: {
            get() {
                return this.requestOptions.statuses.find(status => status.key === this.requestStatus)
            },
            set: function(status) {
                this.requestStatus = !!status ? status.key : null
            }
        },
    },


    data: function () {
        return {
            newEmail: {
                toAddresses: [],
                ccAddresses: [],
                bccAddresses: currentUser('settings') && currentUser('settings')['fillBCC'] ? [{email: currentUser('email')}] : [],
                subject: null,
                textHtml: emailBody('', ''),
                folder: 'sent',
                todoDate: null,
                _files: []
            },
            organisation: currentUser('currentOrganisation') ? currentUser('currentOrganisation').name : '',

            fromEmail : null,

            userPhoneDirect: currentUser('phoneDirect'),

            formData: new FormData(),

            optionTags: defaultEmailTags,

            toContacts:         [],
            toContactPreselect: '',

            bccContacts:         [],
            bccContactPreselect: '',

            ccContacts: [],
            ccContactPreselect: '',

            template: '',
            searchTag: '',
            tags: [],
            templateCategorySelected: '',
            options: [],

            requestsSelected: [],
            requestStatus: null,
            requestOptions: null,

            isLoading: {
                send:       false,
                preview:    false,
                toContacts: false,
                ccContacts: false,
                bccContacts: false,
                draft: false
            }
        }
    },


    methods: {
        changeOrganisation: function(organisation) {
              this.organisation = organisation.name;
              this.fillEmail();
        },

        fillEmail: function () {

            // When selecting a template we need to compile it first
            this.$nextTick(() => {
                if (this.template) {
                    let textHtml = '';

                    let textPhone = '';
                    if(this.userPhoneDirect){
                        textPhone = '(Dw. ' + this.userPhoneDirect + ')';
                    }

                    if (this.emailSelected) {
                        // If Reply of Forward
                        this.$set(this.newEmail, 'subject',  this.newEmail.subject);
                        textHtml = emailBody(this.organisation, this.template.content, textPhone, this.emailSelected);

                    } else {
                        this.$set(this.newEmail, 'subject',  this.template.subject);
                        textHtml = emailBody(this.organisation, this.template.content, textPhone);
                    }

                    this.$set(this.newEmail, 'textHtml', textHtml);
                }

                this.preview();


            });
        },

        customTagLabel: function ({ id, name }) {
            return name;
        },


        getFullName: function (contact) {
            let name = contact.firstName || '';

            if (contact.lastName) {
                name += (name ? ' ' : '') + contact.lastName;
            }

            return name;
        },


        prepareForSending: function () {
            let copyNewEmail = JSON.parse(JSON.stringify(this.newEmail)),
                form = {},
                incorrectAddress = false,

                prepareContact = (contact) => {
                        if (!validateEmail(contact.email)) {
                            incorrectAddress = true;
                        }

                        return {
                            email: contact.email.trim(),
                            name:  this.getFullName(contact)
                        }
                    };

            copyNewEmail.toAddresses = copyNewEmail.toAddresses.map(prepareContact);
            copyNewEmail.bccAddresses = copyNewEmail.bccAddresses.map(prepareContact);
            copyNewEmail.ccAddresses = copyNewEmail.ccAddresses.map(prepareContact);

            if (incorrectAddress) {
                notifyError('Falsche email Adresse!');
                return false;
            }


            if (!copyNewEmail.subject) {
                notifyError('Der Betreff darf nicht leer sein!');
                return false;
            }
            copyNewEmail.subject = copyNewEmail.subject.replace("\n", '');


            if (!copyNewEmail.textHtml) {
                notifyError('Die Nachricht darf nicht leer sein!');
                return false;
            }


            if (copyNewEmail.links && copyNewEmail.links.length > 0) {
                // Transforming orders to references
                copyNewEmail.links = copyNewEmail.links.map(link => Object.assign(
                    link,
                    { order: link.order.id ? '/api/orders/' + link.order.id : link.order })
                );

            } else {
                copyNewEmail.links = this.ordersSelected.map(order => ({ order: '/api/orders/' + order.id }));
            }


            copyNewEmail['_files']               = this.newEmail._files.filter(item => !item.id);
            copyNewEmail['_attachedDocumentIds'] = this.newEmail._files
                                                        .filter(item => item.id && !item.disposition)
                                                        .map(item => item.id);
            copyNewEmail['_attachmentsForwarded'] = this.newEmail._files.filter(item => item.disposition);

            if (this.requestStatus) {
                copyNewEmail['requestStatus'] = this.requestStatus;
                copyNewEmail['requests'] = this.requestsSelected.map(({ id }) => `/api/requests/${id}`);
                if(this.requestID) {
                    copyNewEmail['requests'] = [`/api/requests/${this.requestID}`];
                }
            }

            copyNewEmail['tags'] = this.tags.map(({name}) => name);

            this.formData = jsonToFormData(copyNewEmail);


            return true;
        },


        send: function (close) {
            if (this.prepareForSending()) {
                this.isLoading.send = true;

                axios.post('/api/emails/send', this.formData)
                    .then(response => {
                        notifySuccess('Email gesendet!');
                        if (close === 'close') { this.$emit('close'); }
                        this.formData = new FormData();
                        const requestsChanged = !!this.requestStatus && this.requestsSelected.length > 0;
                        this.$emit('closeModal', requestsChanged);
                    }, error => { notifyError('Konnte email nicht senden! Server Error!'); })
                    .then(() => {
                        // Always executed (success or error)
                        this.isLoading.send = false;
                    });
            }
        },


        addContact: function (contact, type = 'to') {
            type = type + 'Addresses';
            this.newEmail[type].push({ email: contact });
        },


        selectContact: function (type = 'to') {
            type = type + 'ContactPreselect';
            this[type] = '';
        },


        tryContactPreselect: function (type = 'to') {
            type = type + 'ContactPreselect';
            let addressType = type + 'Addresses';
            // When losing focus add the email written (even without and enter/select)
            if (this[type]) {
                this.newEmail[addressType].push({ email: this[type] });
            }
        },


        addUsersInContacts: function (contacts) {
            let usersArray = this.users.filter(item => item.userEmails.length > 0).map(item => {
                let email = null;
                if(this.ordersSelected && this.ordersSelected[0]) {
                    email = item.userEmails.find(userEmail => userEmail.organisation.id === this.ordersSelected[0].organisationFilter.id);
                }
                if(!email) {
                    email = item.userEmails[0];
                }
                return {
                    ...item,
                    email: email.email,
                }
            });

            contacts.push(...usersArray);

        },



        searchContacts: function (search, contactsType) {
            search = search.toLowerCase();
            this[contactsType] = [];
            let contacts = [];

            if (this.hasClients && this.recipient) {
                contacts = JSON.parse(JSON.stringify(this.recipient.client.contacts));
            } else if(this.requestID) {
                contacts = JSON.parse(JSON.stringify(this.initialContacts));
            } else if (this.ordersSelected) {
                contacts = JSON.parse(JSON.stringify(this.ordersSelected[0].client.contacts));
            }
            if(contactsType !== 'toContacts'){
                this.addUsersInContacts(contacts)
            }
            this[contactsType] = contacts.filter(c =>
                c.email && (
                ((c.lastName || c.firstName) && (c.firstName + ' ' + c.lastName).toLowerCase().indexOf(search) !== -1) ||
                (c.email.toLowerCase().indexOf(search) !== -1))
            ).sort((a, b) => {
                if ((a.firstName && b.firstName) ) {
                    if ((a.firstName + ' ' + a.lastName) > (b.firstName + ' ' + b.lastName)) {
                        return 1;
                    }
                    else {
                        return -1;
                    }
                } else {
                    if ((a.email) > (b.email)) {
                        return 1;
                    } else {
                        return -1;
                    }
                }
            });
        },


        getContacts: function (search = '', type = 'to') {
            this.searchContacts(search, type + 'Contacts');
        },


        preview: function () {
            let apiQuery = '?',
                ids      = [];

            if (this.newEmail.links) {
                // Getting the ids
                ids = this.newEmail.links.map(link => link.order ? link.order.id : null);
                // Removing the nulls
                ids = ids.filter(id => id !== null);
                // Only unique (as we may have multiple links from the same order)
                ids = uniq(ids);

                if (ids.length > 0) {
                    apiQuery += 'orders[]=' + ids.join('&orders[]=');
                }

            } else if (this.ordersSelected.length > 0) {
                apiQuery += 'orders[]=' + this.ordersSelected.map(order => order.id).join('&orders[]=');
            }

            apiQuery += '&';
            apiQuery += this.newEmail.toAddresses.map(contact => contact.id ? 'toContacts[]=' + contact.id : '').join('&');

            apiQuery += '&';
            apiQuery += this.newEmail.bccAddresses.map(contact => contact.id ? 'bccContacts[]=' + contact.id : '').join('&');

            apiQuery += '&';
            apiQuery += this.newEmail.ccAddresses.map(contact => contact.id ? 'ccContacts[]=' + contact.id : '').join('&');

            apiQuery += '&';
            apiQuery += this.requestsSelected.map(request => 'requests[]=' + request.id).join('&');

            apiQuery += '&';
            apiQuery += this.requestID ? ('requests[]=' + this.requestID) : '';

            this.isLoading.preview = true;

            axios.post(
                    '/api/email_preview' + apiQuery,
                    {
                        content: this.newEmail.textHtml,
                        subject: this.newEmail.subject
                    },
                    {
                        headers: {'X-LOCALE': this.template.locale ? this.template.locale : 'de_DE'}
                    }
                ).then(response => {
                    this.newEmail.textHtml = response.data.content;
                    this.newEmail.subject  = response.data.subject;
                    this.isLoading.preview = false;

                }, error => {
                    this.isLoading.preview = false;
                    notifyError(error.response.data + '! Server Error!');
                });
        },


        getTemplates: function () {
            axios.get('/api/email_templates').then(response => {
                this.$store.commit('updateTemplates', response.data);
                // Seting the initial template category value
                this.$nextTick(() => {
                    this.preselectTemplate();
                });

            }, error => { notifyError('Vorlagen konnten nicht geladen werden! Server Error!'); });
        },

        preselectTemplate: function() {
            if (this.templateCategories.length > 0) {
                this.templateCategorySelected = this.templateCategories[0];
                let standardTemplates = this.templates

                if(this.selectTemplate){
                     standardTemplates = standardTemplates
                        .filter(template => template.defaultArray.includes(this.selectTemplate));
                } else {
                    const requestType = this.requestType ? this.requestType : 'client';
                    standardTemplates = standardTemplates
                        .filter(template => template.defaultArray.includes(requestType));
                }

                if (this.templates.length > 0 && standardTemplates.length > 0) {
                    this.template = standardTemplates[0];
                    this.templateCategorySelected = standardTemplates[0].category;
                    this.fillEmail()
                }
            }
        },


        doReplyOrForward: function () {
            const selectedEmail = JSON.parse(JSON.stringify(this.emailSelected));


            // It is an action (reply, replyAll or forward)
            this.newEmail.subject = (this.action === 'forward' ? 'FW: ' : 'RE: ') + selectedEmail.subject;
            this.newEmail.toAddresses = [{
                email:     selectedEmail.fromAddress,
                firstName: selectedEmail.fromName,
                lastName:  null
            }],
            this.newEmail.links       = selectedEmail.links;

            let textPhone = '';
            if(this.userPhoneDirect){
                textPhone = '(Dw. ' + this.userPhoneDirect + ')';
            }
            if(this.action === 'forward') {
                this.newEmail._files = selectedEmail.attachments.filter(item => item.disposition === 'attachment').map(item => ({
                    ...item,
                    name: item.fileName,
                }));
            }

            this.newEmail.textHtml    = emailBody(this.organisation, '', textPhone, selectedEmail);
        },


        getOptions: function () {
            let options = JSON.parse(localStorage.getItem('options'));

            if (options && options.email && options.email.template && options.lastUpdates &&
                options.lastUpdates.email && moment(options.lastUpdates.email) >= moment('24.11.2020', 'DD.MM.YYYY')) {
                this.options = options.email.template;

            } else {
                axios.options('/api/email_templates').then(response => {
                    if (!options)       { options       = {}; }
                    if (!options.email) { options.email = {}; }
                    if (!options.lastUpdates) { options.lastUpdates = {}; }

                    options.lastUpdates.email = moment().startOf('day');
                    options.email.template = response.data.category;
                    this.options = options.email.template;
                    localStorage.setItem('options', JSON.stringify(options));
                });
            }
        },

        saveDraft: function() {
            if (this.prepareForSending()) {
                this.isLoading.draft = true;

                axios.post('/api/emails/draft/save', this.formData)
                    .then(response => {
                        notifySuccess('Enwurf wurde erfolgreich im Outlook Entwürfe Ordner gespeichert');
                    }, error => { notifyError('Der Entwurf konnte nicht erstellt werden! Server Error!'); })
                    .then(() => {
                        // Always executed (success or error)
                        this.isLoading.draft = false;
                        this.formData = new FormData();
                    });
            }
        },




        selectAllRequests: function() {
            this.$nextTick(() => {
                if (this.$refs.requests) {
                    this.$refs.requests.selectAll()
                }
            })
        },


        updateRequests(value) {
            this.requestsSelected = value;
            this.fillEmail()
        },

        fetchCurrentEmail: function () {
            this.$nextTick(() => {
                let myOrganisation = null;
                if(this.ordersSelected && this.ordersSelected[0] && this.ordersSelected[0].organisationFilter){
                    myOrganisation = "/api/organisations/" + this.ordersSelected[0].organisationFilter.id;
                    if(currentUser('currentOrganisation').id !== this.ordersSelected[0].organisationFilter.id){
                        this.updateCurrentOrganisation('/api/organisations/' + this.ordersSelected[0].organisationFilter.id);
                    }
                } else {
                    myOrganisation = "/api/organisations/" + currentUser('currentOrganisation').id;
                }
                this.fromEmail = currentUser('userEmails').find(email => email.organisation === myOrganisation);
            });
        },

        updateCurrentOrganisation: function (organisation) {
            editUser({
                id: currentUser('id'),
                data: {
                    currentOrganisation: organisation,
                },
            }).then(response => {
                //notifySuccess('Die Einstellungen wurden aktualisiert!');
                const user = JSON.parse(localStorage.getItem('user'));
                user.user.currentOrganisation = response.data.currentOrganisation;
                this.organisation = response.data.currentOrganisation.name;
                localStorage.setItem('user', JSON.stringify(user));
                this.$emit('input', email);
            }, error => {
                notifyError("Die Einstellungen konnten nicht aktualisiert werden!")
            })
        },


        getValueWithKey
    },


    created: function () {
        // At least template category options
        this.getOptions();


        getUsers().then(response => {
            this.$store.commit('updateUsers', response.data);
        }, error => { });



        this.toContacts = this.initialContacts;
        // Set initial value (primary contact if any)
        if (this.initialContacts.length > 0) {
            const contact = this.initialContacts.find(item => item.type === 'primary');

            this.newEmail.toAddresses = contact ? [contact] : [this.initialContacts[0]];
        }

        if (this.emailSelected) {
            this.doReplyOrForward();
        }


        if (this.preselectedContact) {
            this.newEmail.toAddresses.push(this.preselectedContact);
        }

        if (this.attachDocument){
            this.newEmail._files.push(this.attachDocument)
        }

        if (this.templates.length === 0) {
            this.getTemplates();

        } else {
            // Seting the initial template category value
            this.preselectTemplate()
        }
    },


    watch: {
        hasClients: {
            handler: function () {
                if (!this.requestOptions) {
                    optionsHotelRequests().then(response => {
                        this.requestOptions = response.data;

                    }, error => { notifyError('Couldn\'t load the Request Options'); });
                }
            },
            immediate: true
        },
        ordersSelected: function(value) {
            this.fetchCurrentEmail();
        },


        'newEmail.toAddresses': function () {
            if (this.newEmail.toAddresses) {
                const unique = uniqBy(this.newEmail.toAddresses, item => item.email);

                if (unique.length < this.newEmail.toAddresses.length) {
                    this.newEmail.toAddresses = unique;
                }
            }
        },


        'newEmail.bccAddresses': function () {
            if (this.newEmail.bccAddresses) {
                const unique = uniqBy(this.newEmail.bccAddresses, item => item.email);

                if (unique.length < this.newEmail.bccAddresses.length) {
                    this.newEmail.bccAddresses = unique;
                }
            }
        },

        'newEmail.ccAddresses': function () {
            if (this.newEmail.ccAddresses) {
                const unique = uniqBy(this.newEmail.ccAddresses, item => item.email);

                if (unique.length < this.newEmail.ccAddresses.length) {
                    this.newEmail.ccAddresses = unique;
                }
            }
        },

        'template.id': function() {
            this.tags = this.template ?
                this.template.tags.map(tag => {
                    return {
                        name: tag
                    }
                }) :
                []
        },

        templateCategorySelected: function() {
            if(this.templatesFiltered.length > 0){
                let template = this.templatesFiltered.find(item => item.name === this.template.name);
                this.template = template ? template : this.templatesFiltered[0];
                this.fillEmail();
            }
        },


        recipient: {
            handler: function () {
                if (this.recipient) {
                    const contacts = this.recipient.client.contacts

                    this.toContacts = contacts;

                    // Set initial value (primary contact if any)
                    if (contacts.length > 0 && !this.emailSelected) {
                        const contact = contacts.find(item => item.type === 'primary');

                        this.newEmail.toAddresses = contact ? [contact] : [contacts[0]];
                    }
                }
            },
            immediate: true
        }
    },


    components: {
        InputDate,
        EmailSelect,
        EmailTemplateSelect,
        TagSelect,
        Attachments,
        TinyMce,
        Loading,
        Multiselect,
        RequestsSelect
    }
}

</script>
