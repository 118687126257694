<template>
    <div class="simple-box">
        <div><b>REISE AUSWÄHLEN UM LEISTUNGSTRÄGER UND LEISTUNGEN ANZUZEIGEN</b></div>
        <div>
            <label>Vorgänge:</label>
            <multiselect
                class               = "is-order-select"
                v-model             = "ordersSelected"
                v-bind:options      = "orders"
                v-bind:custom-label = "orderLabel"
                v-bind:multiple     = "true"

                v-bind:options-limit    = "10"
                v-bind:loading          = "isLoading.order"
                v-bind:internal-search  = "false"

                track-by    = "id"
                placeholder = ""

                v-on:search-change = "orderSearch"
            >
                <template slot="option" slot-scope="props">
                    <span class="option__id" v-bind:title="'#' + props.option.id"># {{ props.option.id }}</span>

                    <span class="option__name" v-bind:title="props.option.client.companyName"><i class="material-icons">perm_identity</i> {{ props.option.client.companyName }}</span>

                    <span class="option__name" v-bind:title="props.option.groupName"><i class="material-icons">group</i> {{ props.option.groupName }}</span>

                    <span class="option__name" v-bind:title="getDestinations(props.option)"><i class="material-icons">flag</i> {{ getDestinations(props.option) }}</span>

                    <i class="material-icons">date_range</i> {{ props.option.startAt | dayMonth }} - {{ props.option.endAt | fullYear }}
                </template>
                <span slot="caret" v-if="orders.length === 0"></span>
            </multiselect>
        </div>

        <div v-if="hasClients">
            <label>Leistungsträger:</label>
            <multiselect
                class               = "is-order-select"
                v-model             = "clientSelected"
                v-bind:options      = "clients"
                v-bind:custom-label = "clientLabel"
                track-by            = "feID"


                v-bind:loading       = "isLoading.client"
                v-bind:disabled      = "clients.length === 0"
                v-bind:placeholder   = "clients.length === 0 ? 'Es gibt keine Anbieter oder Agenturen für diese Anfrage' : ''"

            ><span slot="caret" v-if="clients.length === 0"></span></multiselect>
        </div>
    </div>
</template>



<script>
import throttle from 'lodash/throttle';
import has from 'lodash/has';
import get from 'lodash/get';

import Loading from '@components/Loading';
import { Multiselect } from '@components/form';
import { dayMonth, fullYear } from '@utilities/functions';
import { getOrders, getRequestsByOrder } from '@api';
import { notifyError } from '@components/Notification';
import mixins from "@orders/placeholders/components/forms/mixins";
import axios from 'axios';


const orderQueryString = '_groups[]=order_list_read&_groups[]=order_read_extended_contacts&_groups[]=contact_read&_groups[]=contact_read_relations&_groups[]=provider_read_list&_groups[]=agency_read_list';


export default {
    mixins: [mixins],

    props: {
        preselectedOrders: { type: Array,   required: true },
        hasClients:        { type: Boolean, required: true },
        params: {},
    },


    computed: {
        clients: function () { return [...this.providers, ...this.agencies]; },


        preselectedClient: function () {
            if (this.$store.state.preselectedClient) {
                return this.$store.state.preselectedClient.client;

            } else {
                return null;
            }
        }
    },


    data: function () {
        return {
            ordersSelected: [],
            orders: [],

            clientSelected: null,
            providers:  [],
            agencies:   [],

            isLoading: {
                order: false,
                client: false
            }
        }
    },


    methods: {
        orderLabel: function (order) {
            return '# ' + order.orderNumber +
                    ' - ' + order.client.companyName +
                    ' - ' + dayMonth(order.startAt) + ' - ' + fullYear(order.endAt);
        },


        clientLabel: function (client) {
            return client.client.name;
        },


        orderSearch: throttle(function (query) {
            if (query) {
                let queryString = '?_search=' + query;

                // For getting basic info about the order (which by default is all closed)
                queryString += '&' + orderQueryString;

                this.isLoading.order = true;

                this.callCancelRequest();
                let cancelToken = this.cancelSource.token;

                getOrders(queryString, this.params, cancelToken).then(response => {
                    this.orders = response.data;
                }, error => {
                    if(!axios.isCancel(error)) {
                        notifyError('Die Reisen konnten nicht geladen werden! Server error!');
                    }
                })
                .then(() => { this.isLoading.order = false; });
            }
        }, 900),


        addToAgencies: function (request) {
            const index = this.agencies.findIndex(agencyItem => agencyItem.client.id === request.agency.id);

            if (index !== -1) {
                // We found it. We just need to add the request to its list
                this.agencies[index].requests.push(request);

            } else {
                // No provider found. We add the provider with its request
                this.agencies.push({
                    feID:     'agency-' + request.agency.id,
                    client:   request.agency,
                    requests: [request]
                });
            }
        },


        addToProviders: function (request) {
            var provider    = null,
                requestType = null;

            if (request.requestType === 'hotel') {
                provider    = request.hotel;
                requestType = 'hotel';
            } else if (request.requestType === 'ferry') {
                provider    = request.ferry;
                requestType = 'ferry';
            } else if (request.requestType === 'airline') {
                provider    = request.airline;
                requestType = 'airline';
            } else if(request.provider) {
                provider    = request.provider;
                requestType = request.provider.providerType;
            }

            const index = this.providers.findIndex(providerItem => providerItem.client.id === provider.id);

            if (index !== -1) {
                // We found it. We just need to add the request to its list
                this.providers[index].requests.push(request);

            } else {
                // No provider found. We add the provider with its request
                this.providers.push({
                    feID:     requestType + '-' + provider.id,
                    client:   provider,
                    requests: [request]
                });
            }
        },


        setPreselectedClient: function () {
            if (this.preselectedClient) {
                if (this.preselectedClient.providerType) {
                    // Is a Provider
                    this.clientSelected = this.providers.find(item => item.client.id === this.preselectedClient.id);
                } else {
                    // Is an Agency
                    this.clientSelected = this.agencies.find(item => item.client.id === this.preselectedClient.id);
                }
            }
        },


        getRequests: function () {
            let calls = [];

            this.isLoading.client = true;

            this.ordersSelected.forEach(order => {
                calls.push(getRequestsByOrder(order.id, '&_groups[]=order_list_read&_groups[]=provider_read&_groups[]=agency_read&_groups[]=order_request_contingent_read&_groups[]=order_contingent_item_read&_groups[]=order_request_deadline_read&_groups[]=order_request_status_read&_groups[]=other_service_type_read&_groups[]=contact_read'));
            });

            Promise.all(calls).then(arrays => {
                arrays.forEach((responses, index) => {
                    [].concat(...responses.map(response => response.data)).forEach(request => {
                        // Adding the order so we can group them by request later on
                        request.orderID = this.ordersSelected[index].id;

                        const requestSortHelper = getRequestSortHelper(request);
                        if (requestSortHelper) {
                            request._sort = (get(this.ordersSelected, [index, 'sortHelper'], []) || [])
                                .findIndex(sortHelper => sortHelper === requestSortHelper);
                        }

                        if (request.agency) {
                            this.addToAgencies(request);

                        } else {
                            this.addToProviders(request);
                        }
                    });
                });

                this.setPreselectedClient();

            }, error => { notifyError('Die Provider konnten nicht geladen werden! Server Error!'); })
            .then(() => { this.isLoading.client = false; });
        },


        processPreselectedOrders: function () {
            let apiQuery = [];

            // Reseting
            this.ordersSelected = [];
            this.orders = [];

            this.preselectedOrders.forEach(order => {
                if (!order.id) {
                    // we only have the id
                    apiQuery.push(order);

                } else {
                    this.ordersSelected.push(order);
                    this.orders.push(order);
                }
            });

            // If we get only the ID, we need to retriev the entire order

            if (apiQuery.length > 0) {
                this.isLoading.order = true;

                apiQuery = 'id[]=' + apiQuery.join('&id[]=') + '&';

                getOrders('?' + apiQuery + orderQueryString, this.params)
                    .then(response => {
                            this.ordersSelected.push(...response.data);
                            this.orders.push(...response.data);

                        }, error => { notifyError('Konnte die Bestellungen nicht bekommen! Server Error!'); })
                        .then(() => { this.isLoading.order = false; } );
            }
        },


        getDestinations: function (order) {
            return order.trip.destinations.map(item => item.name).join(', ');
        }
    },


    created: function () {
        this.processPreselectedOrders();


        if (this.preselectedClient) {
            this.setPreselectedClient();
        }
    },


    watch: {
        ordersSelected: function () {
            // Reset everything
            this.clientSelected = null;
            this.agencies = [];
            this.providers = [];
            this.$emit('updateOrders', this.ordersSelected);
            this.$emit('update', this.clientSelected);

            if (this.hasClients && this.ordersSelected.length > 0) {
                this.getRequests();
            }
        },


        clientSelected: function () {
            if (this.hasClients) {
                this.$emit('update', this.clientSelected);
            }
        },


        hasClients: function () {
            if (this.hasClients && this.ordersSelected.length > 0) {
                this.getRequests();
            }
        }
    },


    filters: {
        dayMonth,
        fullYear
    },


    components: {
        Loading,
        Multiselect
    }
}

const getRequestSortHelper = function (request) {
    if (!!request.placeholder && typeof request.placeholder === 'string') {
        const id = request.placeholder.split('/').pop();

        if (request.requestType ==='hotel')  {
            return `hotel-${id}`;
        } else if (request.requestType ==='ferry') {
            return `ferry-${id}`
        } else if (request.requestType ==='train') {
            return `train-${id}`
        } else if (request.requestType ==='airline')  {
            return `airline-${id}`
        } else {
            return `other-${id}`
        }
    } else {
        return false
    }
}
</script>
