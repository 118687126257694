<template>
    <i class="material-icons" v-if=     "type === 'tour_guide'"           title="Tour Guide">record_voice_over</i>
    <i class="material-icons" v-else-if="type === 'boat_trip'"            title="Boat Trip">rowing</i>
    <i class="material-icons" v-else-if="type === 'restaurant'"           title="Restaurant">restaurant_menu</i>
    <i class="material-icons" v-else-if="type === 'food_on_board'"        title="Essen an Bord">restaurant_menu</i>
    <i class="material-icons" v-else-if="type === 'entrances_and_fees'"   title="Entrances And Fees">monetization_on</i>
    <i class="material-icons" v-else-if="type === 'conservation_program'" title="Conservation Program">album</i>
    <i class="material-icons" v-else-if="type === 'train_ride'"           title="Train Ride">directions_railway</i>
    <i class="material-icons" v-else-if="type === 'bus_presentation'"     title="Bus Presentation">directions_bus</i>
    <i class="material-icons" v-else-if="type === 'tastings'"             title="Tastings">local_bar</i>
    <i class="material-icons" v-else-if="type === 'tourist_train'"        title="Tastings">directions_transit</i>
    <i class="material-icons" v-else-if="type === 'other'"                title="Other">view_module</i>
    <i class="material-icons" v-else                                      title="Other">map</i>
</template>



<script>
export default {
    props: {
        type: { type: String, required: true }
    }
}
</script>
