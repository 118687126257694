import anime from 'animejs';

export default {
    data: function () {
        return {
            isOpen: false,
        }
    },

    mounted: function () {
        if (this.$refs.collapse && !this.isOpen) {
            this.collapse(0);
        }
    },

    methods: {
        collapse: function (duration = 400) {
            const targets = this.$refs.collapse;
            this.isOpen = false;

            anime({
                targets,
                duration: duration,
                easing: 'easeOutExpo',
                opacity: [1, 0],
                height: 0,
            });
        },

        expand: function () {
            const targets = this.$refs.collapse;
            const height = targets.scrollHeight;
            this.isOpen = true;

            anime({
                targets,
                duration: 400,
                easing: 'easeOutExpo',
                opacity: [0, 1],
                height,
                complete: function () {
                    targets.removeAttribute('style');
                }
            });
        },

        toggle: function () {
            if (!this.isOpen) {
                this.expand();
            } else {
                this.collapse();
            }
        },
    }
}
