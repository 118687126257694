var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new-email-modal-form" },
    [
      _vm.hasClients && _vm.recipient
        ? _c("h3", [_vm._v("EMAIL " + _vm._s(_vm.recipient.client.name))])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasClients && _vm.recipient && _vm.recipient.requests
        ? [
            _c("requests-select", {
              ref: "requests",
              attrs: {
                requests: _vm.recipient.requests,
                options: _vm.requestOptions,
              },
              on: { update: _vm.updateRequests },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "buttons-wrapper" }, [
        _c(
          "div",
          {
            staticClass: "is-flex",
            staticStyle: { position: "relative", "margin-left": "74%" },
          },
          [
            _c(
              "button",
              {
                staticClass: "button is-large",
                class: { "is-loading": _vm.isLoading.draft },
                staticStyle: { margin: "10px" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.saveDraft.apply(null, arguments)
                  },
                },
              },
              [_vm._v("Outlook Entwurf erstellen")]
            ),
            _vm._v(" "),
            _c("div", [
              _c(
                "button",
                {
                  staticClass: "button is-primary is-large",
                  class: { "is-loading": _vm.isLoading.send },
                  staticStyle: { margin: "10px" },
                  on: {
                    click: function ($event) {
                      return _vm.send("close")
                    },
                  },
                },
                [
                  _c("i", { staticClass: "material-icons" }, [_vm._v("check")]),
                  _vm._v(" Email senden"),
                ]
              ),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "input-wrapper" },
        [
          _c("label", [_vm._v("Von:")]),
          _vm._v(" "),
          _c("email-select", {
            on: { setOrganisation: _vm.changeOrganisation },
            model: {
              value: _vm.fromEmail,
              callback: function ($$v) {
                _vm.fromEmail = $$v
              },
              expression: "fromEmail",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "input-wrapper" },
        [
          _c("label", [_vm._v("An:")]),
          _vm._v(" "),
          _c(
            "multiselect",
            {
              attrs: {
                options: _vm.toContacts,
                multiple: true,
                taggable: true,
                loading: _vm.isLoading.toContacts,
                "internal-search": false,
                label: "email",
                "track-by": "email",
                placeholder: "",
              },
              on: {
                tag: (item) => _vm.addContact(item, "to"),
                open: function ($event) {
                  return _vm.getContacts("", "to")
                },
                "search-change": (search) => _vm.getContacts(search, "to"),
                select: function ($event) {
                  return _vm.selectContact("to")
                },
                close: function ($event) {
                  return _vm.tryContactPreselect("to")
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "option",
                    fn: function (props) {
                      return props.option.firstName ||
                        props.option.email ||
                        props.option.lastName
                        ? [
                            props.option.firstName
                              ? _c("span", { staticClass: "option__name" }, [
                                  _c("i", { staticClass: "material-icons" }, [
                                    _vm._v("perm_identity"),
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        props.option.firstName +
                                          " " +
                                          props.option.lastName +
                                          " - " +
                                          props.option.email
                                      )
                                  ),
                                ])
                              : _c("span", { staticClass: "option__name" }, [
                                  _c("i", { staticClass: "material-icons" }, [
                                    _vm._v("perm_identity"),
                                  ]),
                                  _vm._v(" " + _vm._s(props.option.email)),
                                ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "option__type" },
                              [
                                props.client
                                  ? [
                                      _c(
                                        "i",
                                        { staticClass: "material-icons" },
                                        [_vm._v("business")]
                                      ),
                                      _vm._v(
                                        " Company: " +
                                          _vm._s(
                                            props.option.client.companyName
                                          )
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]
                        : undefined
                    },
                  },
                ],
                null,
                true
              ),
              model: {
                value: _vm.newEmail.toAddresses,
                callback: function ($$v) {
                  _vm.$set(_vm.newEmail, "toAddresses", $$v)
                },
                expression: "newEmail.toAddresses",
              },
            },
            [
              _vm._v(" "),
              _vm.toContacts.length === 0
                ? _c("span", { attrs: { slot: "caret" }, slot: "caret" })
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "input-wrapper" },
        [
          _c("label", [_vm._v("CC:")]),
          _vm._v(" "),
          _c(
            "multiselect",
            {
              attrs: {
                options: _vm.ccContacts,
                multiple: true,
                taggable: true,
                loading: _vm.isLoading.ccContacts,
                "internal-search": false,
                label: "email",
                "track-by": "email",
                placeholder: "",
              },
              on: {
                tag: (item) => _vm.addContact(item, "cc"),
                open: function ($event) {
                  return _vm.getContacts("", "cc")
                },
                "search-change": (search) => _vm.getContacts(search, "cc"),
                select: function ($event) {
                  return _vm.selectContact("cc")
                },
                close: function ($event) {
                  return _vm.tryContactPreselect("cc")
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "option",
                    fn: function (props) {
                      return props.option.firstName ||
                        props.option.email ||
                        props.option.lastName
                        ? [
                            props.option.firstName
                              ? _c("span", { staticClass: "option__name" }, [
                                  _c("i", { staticClass: "material-icons" }, [
                                    _vm._v("perm_identity"),
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        props.option.firstName +
                                          " " +
                                          props.option.lastName +
                                          " - " +
                                          props.option.email
                                      )
                                  ),
                                ])
                              : _c("span", { staticClass: "option__name" }, [
                                  _c("i", { staticClass: "material-icons" }, [
                                    _vm._v("perm_identity"),
                                  ]),
                                  _vm._v(_vm._s(props.option.email)),
                                ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "option__type" },
                              [
                                props.client
                                  ? [
                                      _c(
                                        "i",
                                        { staticClass: "material-icons" },
                                        [_vm._v("business")]
                                      ),
                                      _vm._v(
                                        " Company: " +
                                          _vm._s(
                                            props.option.client.companyName
                                          )
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]
                        : undefined
                    },
                  },
                ],
                null,
                true
              ),
              model: {
                value: _vm.newEmail.ccAddresses,
                callback: function ($$v) {
                  _vm.$set(_vm.newEmail, "ccAddresses", $$v)
                },
                expression: "newEmail.ccAddresses",
              },
            },
            [
              _vm._v(" "),
              _vm.ccContacts.length === 0
                ? _c("span", { attrs: { slot: "caret" }, slot: "caret" })
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "input-wrapper" },
        [
          _c("label", [_vm._v("BCC:")]),
          _vm._v(" "),
          _c(
            "multiselect",
            {
              attrs: {
                options: _vm.bccContacts,
                multiple: true,
                taggable: true,
                loading: _vm.isLoading.bccContacts,
                "internal-search": false,
                label: "email",
                "track-by": "email",
                placeholder: "",
              },
              on: {
                tag: (item) => _vm.addContact(item, "bcc"),
                open: function ($event) {
                  return _vm.getContacts("", "bcc")
                },
                "search-change": (search) => _vm.getContacts(search, "bcc"),
                select: function ($event) {
                  return _vm.selectContact("bcc")
                },
                close: function ($event) {
                  return _vm.tryContactPreselect("bcc")
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "option",
                    fn: function (props) {
                      return props.option.firstName ||
                        props.option.email ||
                        props.option.lastName
                        ? [
                            props.option.firstName
                              ? _c("span", { staticClass: "option__name" }, [
                                  _c("i", { staticClass: "material-icons" }, [
                                    _vm._v("perm_identity"),
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        props.option.firstName +
                                          " " +
                                          props.option.lastName +
                                          " - " +
                                          props.option.email
                                      )
                                  ),
                                ])
                              : _c("span", { staticClass: "option__name" }, [
                                  _c("i", { staticClass: "material-icons" }, [
                                    _vm._v("perm_identity"),
                                  ]),
                                  _vm._v(_vm._s(props.option.email)),
                                ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "option__type" },
                              [
                                props.client
                                  ? [
                                      _c(
                                        "i",
                                        { staticClass: "material-icons" },
                                        [_vm._v("business")]
                                      ),
                                      _vm._v(
                                        " Company: " +
                                          _vm._s(
                                            props.option.client.companyName
                                          )
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]
                        : undefined
                    },
                  },
                ],
                null,
                true
              ),
              model: {
                value: _vm.newEmail.bccAddresses,
                callback: function ($$v) {
                  _vm.$set(_vm.newEmail, "bccAddresses", $$v)
                },
                expression: "newEmail.bccAddresses",
              },
            },
            [
              _vm._v(" "),
              _vm.bccContacts.length === 0
                ? _c("span", { attrs: { slot: "caret" }, slot: "caret" })
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "input-wrapper" }, [
        _c("label", [_vm._v("Vorlage:")]),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("div", { staticClass: "select" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.templateCategorySelected,
                      expression: "templateCategorySelected",
                    },
                  ],
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.templateCategorySelected = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                _vm._l(_vm.options, function (category) {
                  return _c(
                    "option",
                    { key: category.key, domProps: { value: category.key } },
                    [_vm._v(_vm._s(category.value))]
                  )
                }),
                0
              ),
            ]),
            _vm._v(" "),
            _c("email-template-select", {
              attrs: { "templates-filtered": _vm.templatesFiltered },
              on: { fillEmail: _vm.fillEmail },
              model: {
                value: _vm.template,
                callback: function ($$v) {
                  _vm.template = $$v
                },
                expression: "template",
              },
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "button is-large is-icon",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.fillEmail.apply(null, arguments)
                  },
                },
              },
              [_c("i", { staticClass: "material-icons" }, [_vm._v("loop")])]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "input-wrapper" }, [
        _c("label", [_vm._v("Betreff:")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.newEmail.subject,
              expression: "newEmail.subject",
            },
          ],
          staticClass: "input",
          attrs: { type: "text" },
          domProps: { value: _vm.newEmail.subject },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.newEmail, "subject", $event.target.value)
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "input-wrapper" },
        [
          _c("label", [_vm._v("Beschreibung:")]),
          _vm._v(" "),
          _c("tag-select", {
            attrs: { "option-tags": _vm.optionTags, "my-class": "is-medium" },
            model: {
              value: _vm.tags,
              callback: function ($$v) {
                _vm.tags = $$v
              },
              expression: "tags",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      ((_vm.hasClients && _vm.recipient && _vm.recipient.requests) ||
        _vm.requestID) &&
      _vm.requestOptions &&
      _vm.requestOptions.statuses
        ? _c(
            "div",
            { staticClass: "input-wrapper" },
            [
              _c("label", [_vm._v("Status ändern:")]),
              _vm._v(" "),
              _c("multiselect", {
                attrs: {
                  placeholder: "nicht ändern",
                  options: _vm.requestOptions.statuses,
                  label: "value",
                  "track-by": "key",
                },
                model: {
                  value: _vm.status,
                  callback: function ($$v) {
                    _vm.status = $$v
                  },
                  expression: "status",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "input-wrapper" },
        [
          _c("label", [_vm._v("Neue Aufgabe:")]),
          _vm._v(" "),
          _c("input-date", {
            model: {
              value: _vm.newEmail.todoDate,
              callback: function ($$v) {
                _vm.$set(_vm.newEmail, "todoDate", $$v)
              },
              expression: "newEmail.todoDate",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "attachments" },
        [
          _c("label", [_vm._v("Anhänge:")]),
          _vm._v(" "),
          _c("attachments", {
            attrs: { ordersSelected: _vm.ordersSelected },
            model: {
              value: _vm.newEmail._files,
              callback: function ($$v) {
                _vm.$set(_vm.newEmail, "_files", $$v)
              },
              expression: "newEmail._files",
            },
          }),
          _vm._v(" "),
          _c("br"),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "pell-wrapper" }, [
        _c("label", [_vm._v("Text:")]),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { "z-index": "0" } },
          [
            _vm.isLoading.preview ? _c("loading") : _vm._e(),
            _vm._v(" "),
            _c("div", [
              _c(
                "div",
                [
                  _c("tiny-mce", {
                    model: {
                      value: _vm.newEmail.textHtml,
                      callback: function ($$v) {
                        _vm.$set(_vm.newEmail, "textHtml", $$v)
                      },
                      expression: "newEmail.textHtml",
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "input-wrapper" }),
      _vm._v(" "),
      _c("div", { staticClass: "buttons-wrapper" }, [
        _c(
          "div",
          {
            staticClass: "is-flex",
            staticStyle: { position: "relative", "margin-left": "74%" },
          },
          [
            _c(
              "button",
              {
                staticClass: "button is-large",
                class: { "is-loading": _vm.isLoading.draft },
                staticStyle: { margin: "10px" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.saveDraft.apply(null, arguments)
                  },
                },
              },
              [_vm._v("Outlook Entwurf erstellen")]
            ),
            _vm._v(" "),
            _c("div", [
              _c(
                "button",
                {
                  staticClass: "button is-primary is-large",
                  class: { "is-loading": _vm.isLoading.send },
                  staticStyle: { margin: "10px" },
                  on: {
                    click: function ($event) {
                      return _vm.send("close")
                    },
                  },
                },
                [
                  _c("i", { staticClass: "material-icons" }, [_vm._v("check")]),
                  _vm._v(" Email senden"),
                ]
              ),
            ]),
          ]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }