import Vuex from 'vuex';


const isDirection = function (email, state, direction) {
    for (let i = 0, len = state.types.length; i < len; i++) {
        if (email.folder === state.types[i].type) {
            return state.types[i].direction === direction;
        }
    }

    return false;
};


export default new Vuex.Store({
    state: {
        types: [
            /*{ title: 'Neue Emails',          type: 'inbox',                  direction: 'in'  },
            { title: 'Gesendet',             type: 'sent',                   direction: 'out' },
            // { title: 'Papierkorb',        type: 'trash' },

            { title: 'Wichtig',              type: 'travelize.important',    direction: 'in'  },
            { title: 'Sonstige Emails',      type: 'travelize.other',        direction: 'in'  },
            { title: 'Erledigt',             type: 'travelize.archive',      direction: 'in'  },
            { title: 'Postausgang Erledigt', type: 'travelize.archive_sent', direction: 'out' },
            // { title: 'Papierkorb',        type: 'travelize.trash' }*/
            { title: 'Posteingang',        type: 'INBOX',          direction: 'in'  },
            { title: 'Postausgang',        type: 'Drafts',         direction: 'in'  },
            { title: 'Gesendete Elemente', type: 'Sent Items',     direction: 'in'  },
            { title: 'Papierkorb',         type: 'Deleted Items',  direction: 'in'  },
            { title: 'Öffentliche Ordner', type: 'Public Folders', direction: 'in'  },
        ],
        emailsServer:   [],
        emailsLive:     [],
        emailsSelected: {
            direction: 'in',
            in:  [],
            out: []
        },


        orders: [],
        clients: [],
        preselectedClient: null,
        users: [],


        action:        null,
        templates:     [],
        updateFolder:  false,
        loadingFolder: false,


        isLoading: false,
    },


    getters: {
        emailsListIn: function (state) {
            return [
                ...state.emailsLive.filter(email   => isDirection(email, state, 'in')),
                ...state.emailsServer.filter(email => isDirection(email, state, 'in'))
            ];
        },


        emailsListOut: function (state) {
            return [
                ...state.emailsLive.filter(email   => isDirection(email, state, 'out')),
                ...state.emailsServer.filter(email => isDirection(email, state, 'out'))
            ];
        },

        emailsListByType: function (state, getters) {
            let list = {
                    in: {},
                    out: {}
                };

            state.types.forEach(type => {
                if (type.direction === 'in') {
                    list.in[type.type]  = getters.emailsListIn.filter(email  => email.folder === type.type);
                } else {
                    list.out[type.type] = getters.emailsListOut.filter(email => email.folder === type.type);
                }
            });

            return list;
        },


        emailsSelected: function (state) {
            return state.emailsSelected[state.emailsSelected.direction];
        },


        email: function (state) {
            // In order to pass arguments to getters we need to return a function
            return ({ id, folder }) => {
                for (let i = 0, len = state.emailsLive.length; i < len; i++) {
                    if (state.emailsLive[i].uid === id && state.emailsLive[i].folder === folder) {
                        return state.emailsLive[i];
                    }
                }
                for (let i = 0, len = state.emailsServer.length; i < len; i++) {
                    if (state.emailsServer[i].id === id && state.emailsServer[i].folder === folder) {
                        return state.emailsServer[i];
                    }
                }

                return null;
            };
        },


        isLive: function (state) {
            return (email) => !email.id;
        },


        isDirection: function (state) {
            return (email, direction) => isDirection(email, state, direction);
        }
    },


    mutations: {
        updateEmailsServer: function (state, list) { state.emailsServer = list; },
        updateEmailsLive:   function (state, list) { state.emailsLive   = list; },


        updateFolder:  function (state, shouldUpdate) { state.updateFolder  = shouldUpdate; },
        loadingFolder: function (state, isLoading)    { state.loadingFolder = isLoading; },


        updateEmailsSelected: function (state, object) {
            if (object.event && object.event.shiftKey) {
                // Multi email select
                const index = state.emailsSelected[state.emailsSelected.direction].findIndex(email =>
                    (object.email.id ? email.id === object.email.id : email.uid === object.email.uid));

                if (index !== -1) {
                    // Deselecting one
                    if (state.emailsSelected[state.emailsSelected.direction].length > 1) {
                        state.emailsSelected[state.emailsSelected.direction].splice(index, 1);
                    }

                } else {
                    // Adding multiselect - at the begging of the array
                    state.emailsSelected[state.emailsSelected.direction].unshift(object.email);
                }

            } else {
                // Simple click
                state.emailsSelected[state.emailsSelected.direction] = [object.email];
            }
        },


        // When we change the folder we need to empty the list and select the email given (the first email if any)
        updateChangeFolder: function (state, object) {
            state.emailsSelected[state.emailsSelected.direction] = object;
        },


        updateDirection: function (state, direction) {
            state.emailsSelected.direction = direction;
        },


        movedFromLive: function (state, emails) {
            // Updating the emailsSelected list
            const indexSelected = state.emailsSelected[state.emailsSelected.direction].findIndex(email => email.uid    === emails.old.uid &&
                                                                                                          email.folder === emails.old.folder);
            if (indexSelected !== -1) {
                state.emailsSelected[state.emailsSelected.direction].splice(indexSelected, 1, emails.new);
            }

            // Deleting the old
            const indexLive = state.emailsLive.findIndex(email => email.uid    === emails.old.uid &&
                                                                  email.folder === emails.old.folder);

            if (indexLive !== -1) {
                state.emailsLive.splice(indexLive, 1);
            }

            // Adding the new
            state.emailsServer.unshift(emails.new);
        },


        updateEmailOnServer: function (state, { id, folder, links, tags }) {
            const index = state.emailsServer.findIndex(email => email.id === id);
            state.emailsServer[index].folder = folder;
            state.emailsServer[index].links  = links;
            state.emailsServer[index].tags   = tags;
        },


        /*
            ==================== INTERNALS - For in VUEX store use ONLY ====================
        */
        internalInitialEmailsSelected: function (state, object) {
            state.emailsSelected[object.direction] = [object.email];
        },


        internalDeleteEmailsSelected: function (state) {
            let index = -1;

            state.emailsSelected[state.emailsSelected.direction].forEach(email => {
                if (email.id) {
                    index = state.emailsServer.findIndex(emailServer => email.id === emailServer.id);
                    if (index !== -1) {
                        state.emailsServer.splice(index, 1);
                    }

                } else {
                    index = state.emailsLive.findIndex(emailLive => email.uid === emailLive.uid && email.folder === emailLive.folder);
                    if (index !== -1) {
                        state.emailsLive.splice(index, 1);
                    }
                }
            });
            state.emailsSelected[state.emailsSelected.direction] = [];
        },
        /* ================================ End of INTERNALS ================================ */


        updateOrders:  function (state, newOrders)  { state.orders  = newOrders; },
        updateClients: function (state, newClients) { state.clients = newClients; },
        updateUsers:   function (state, newUsers)   { state.users   = newUsers; },


        updateAction:  function (state, newAction) {
            if (state.emailsSelected[state.emailsSelected.direction].length > 0) {
                state.action = newAction;

            } else {
                state.action = null;
            }
        },
        updateTemplates: function (state, newTemplates) { state.templates = newTemplates; },
        updateLoading: function (state, newState) { state.isLoading = newState; },

        updatePreselectedClient: function (state, client) { state.preselectedClient = client; }
    },


    actions: {
        internalInitialEmailsSelected: function (context) {
            // Initial IN email selected once we have the list of emails
            if (context.state.emailsSelected.in.length === 0 && context.getters.emailsListIn.length > 0) {
                context.commit('internalInitialEmailsSelected', { direction: 'in', email: context.getters.emailsListIn[0] });
            }
            // Initial OUT email selected once we have the list of emails
            if (context.state.emailsSelected.out.length === 0 && context.getters.emailsListOut.length > 0) {
                context.commit('internalInitialEmailsSelected', { direction: 'out', email: context.getters.emailsListOut[0] });
            }
        },


        updateEmailsServer: function (context, list) {
            // Update the server list
            context.commit('updateEmailsServer', list);
            // Initial Email
            context.dispatch('internalInitialEmailsSelected');
        },


        updateEmailsLive: function (context, list) {
            // Update the live list
            context.commit('updateEmailsLive', list);
            // Initial Email
            context.dispatch('internalInitialEmailsSelected');
        },


        deleteEmailsSelected: function (context) {
            // Delete from List and Empty emailsSelected (for the current direction)
            context.commit('internalDeleteEmailsSelected');
            // Initial Email
            context.dispatch('internalInitialEmailsSelected');
        },
    }
});
