<template>
    <loading v-if="!previewURL"/>
    <iframe v-else style="height: 790px"  id="iframeID" :src="previewURL" ref="iframe" :key="key" />
</template>

<script>

import Loading             from '@components/Loading';
import {getMicrosoftPreviewData} from '@api'
import {notifyError} from "@components/Notification";

export default {
    props: {
        value: {type: Object, required: true}, // Document
    },

    data: function() {
        return {
            key: + new Date(),
            previewURL: null
        }
    },

    components: {
        Loading
    },

    mounted: function() {
        getMicrosoftPreviewData(this.value.id).then(response => {
            this.previewURL = response.data.getUrl;
        }, error => { notifyError('Preview nicht möglich! Server Error!'); });
    }


}
</script>
