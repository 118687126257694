var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "email-attachments" },
        [
          _c("input", {
            ref: "fileUpload",
            staticClass: "is-hidden",
            attrs: { type: "file", multiple: "" },
            on: { input: _vm.addFiles },
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "button is-gray",
              staticStyle: { "margin-bottom": "5px" },
              on: {
                click: function ($event) {
                  return _vm.$refs.fileUpload.click()
                },
              },
            },
            [_vm._v("Anhänge vom Compuer hochladen")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "button is-gray",
              staticStyle: { "margin-bottom": "5px" },
              on: {
                click: function ($event) {
                  _vm.isModalVisible = true
                },
              },
            },
            [_vm._v("Anhänge aus dem Vorgang hochladen")]
          ),
          _vm._v(" "),
          _c("transition", { attrs: { name: "fade" } }, [
            _vm.isModalVisible
              ? _c("div", { staticClass: "attachments-modal" }, [
                  _c("div", { staticClass: "panel" }, [
                    _c("div", { staticClass: "panel-heading" }, [
                      _vm._v("Anhänge"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "panel-block" },
                      [
                        !_vm.isLoading.documents
                          ? [
                              _vm._l(_vm.documents, function (document) {
                                return _c(
                                  "button",
                                  {
                                    key: document.id,
                                    class: {
                                      "is-selected": _vm.isSelected(document),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.toggleAttachment(document)
                                      },
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "material-icons" }, [
                                      _vm._v("check_circle"),
                                    ]),
                                    _vm._v(
                                      "\n                                [" +
                                        _vm._s(_vm.fileType(document)) +
                                        "] " +
                                        _vm._s(document.name) +
                                        " " +
                                        _vm._s(
                                          _vm.formatDate(document.createdAt)
                                        ) +
                                        "\n                            "
                                    ),
                                  ]
                                )
                              }),
                              _vm._v(" "),
                              _vm.documents.length === 0
                                ? _c("div", { staticClass: "is-empty" }, [
                                    _vm._v("Es gibt keine Dokumente"),
                                  ])
                                : _vm._e(),
                            ]
                          : _c("loading"),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("footer", { staticStyle: { display: "block" } }, [
                      _c("div", { staticClass: "level" }, [
                        _c(
                          "button",
                          {
                            staticClass: "button",
                            on: {
                              click: function ($event) {
                                _vm.isModalVisible = false
                              },
                            },
                          },
                          [_vm._v("Keine weiteren Anhänge")]
                        ),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.localAttachments, function (file, index) {
        return _c(
          "div",
          {
            staticClass: "button",
            staticStyle: { "margin-bottom": "5px" },
            attrs: { title: file.name },
            on: {
              click: function ($event) {
                return _vm.previewDocument(file)
              },
            },
          },
          [
            _c("i", {
              staticClass: "fa fa-file",
              attrs: { "aria-hidden": "true" },
            }),
            _vm._v(" " + _vm._s(file.name) + "\n        "),
            _c(
              "button",
              {
                staticClass: "btn-delete",
                attrs: { title: "Löschen" },
                on: {
                  click: function ($event) {
                    return _vm.localAttachments.splice(index, 1)
                  },
                },
              },
              [_c("i", { staticClass: "material-icons" }, [_vm._v("close")])]
            ),
            _vm._v(" "),
            _c("preview", {
              model: {
                value: _vm.previewURL,
                callback: function ($$v) {
                  _vm.previewURL = $$v
                },
                expression: "previewURL",
              },
            }),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }