import debounce from 'lodash/debounce';
import max from 'lodash/max';
import map from 'lodash/map';
import get from 'lodash/get';

import {  notifyError } from '@components/Notification';
import {
    getOrder,
    getClients,
    getAgencies,
    getProviders,
    getOrderConcepts,
    getDayConcepts,
    getPredefinedRoutesList,
    getOrganisations,
    getBanks
} from '@api';
import { fullYear } from '@utilities/functions';


export default {
    data: function () {
        return {
            options: {
                orders:         [],
                orderConcepts:  [],
                dayConcepts:    [],
                clients:        [],
                agencies:       [],
                providers:      [],
                invoices:       [],
                predefinedRouteDetails: [],
                organisations: [],
                banks: []
            },

            isOrderLoading:         false,
            isOrderConceptLoading:  false,
            isDayConceptLoading:  false,
            isClientLoading:        false,
            isAgencyLoading:        false,
            isOrganisationLoading:  false,
            isBankLoading:  false,
            isProviderLoading:      false,
            isInvoiceLoading:       false,
            isPredefinedRouteDetailLoading:       false,
        };
    },


    computed: {
        documentLinks: function () {
            let links = [];

            for (let i = 0; i < this.linksCount; i++) {
                let link = {};

                if (get(this.form, `links.order[${i}]`) && this.form.assignment === 'order') {
                    link.order = `/api/orders/${this.form.links.order[i].id}`;
                } else {
                    link.order = null; }

                if (get(this.form, `links.orderConcept[${i}]`)) {
                    link.orderConcept = `/api/order_concepts/${this.form.links.orderConcept[i].id}`;
                } else {
                    link.orderConcept = null; }

                if (get(this.form, `links.predefinedRouteDetail[${i}]`)) {
                    link.predefinedRouteDetail = `/api/predefined_route_details/${this.form.links.predefinedRouteDetail[i].id}`;
                } else {
                    link.predefinedRouteDetail = null; }

                if (get(this.form, `links.dayConcept[${i}]`)) {
                    link.dayConcept = `/api/day_concepts/${this.form.links.dayConcept[i].id}`;
                } else {
                    link.dayConcept = null; }

                if (get(this.form, `links.client[${i}]`) && this.form.assignment === 'client') {
                    link.client = `/api/clients/${this.form.links.client[i].id}`;
                } else {
                    link.client = null; }

                if (get(this.form, `links.agency[${i}]`)) {
                    link.agency = `/api/agencies/${this.form.links.agency[i].id}`;
                } else {
                    link.agency = null; }

                if (get(this.form, `links.area[${i}]`)) {
                    link.area = `/api/areas/${this.form.links.area[i].id}`;
                } else {
                    link.area = null; }

                if (get(this.form, `links.place[${i}]`)) {
                    link.place = `/api/places/${this.form.links.place[i].id}`;
                } else {
                    link.place = null; }

                if (get(this.form, `links.destination[${i}]`)) {
                    link.destination = `/api/destinations/${this.form.links.destination[i].id}`;
                } else {
                    link.destination = null; }

                if (get(this.form, `links.provider[${i}]`)) {
                    link.provider = `/api/providers/${this.form.links.provider[i].id}`;
                } else {
                    link.provider = null; }

                if (get(this.form, `links.invoice[${i}]`)) {
                    link.invoice = `/api/invoices/${this.form.links.invoice[i].id}`;
                } else {
                    link.invoice = null; }

                if (get(this.form, `links.organisation[${i}]`)) {
                    link.organisation = `/api/organisations/${this.form.links.organisation[i].id}`;
                } else {
                    link.organisation = null; }

                if (get(this.form, `links.bank[${i}]`)) {
                    link.bank = `/api/banks/${this.form.links.bank[i].id}`;
                } else {
                    link.bank = null; }

                links.push(link);
            }

            return links;
        },


        linksCount: function () {
            return max(map(this.form.links, (links => links.length)));
        }
    },


    methods: {
        customLabel: function (order) {
            if (!order.client) {
                return order.orderNumber;

            } else {
                return `# ${order.orderNumber} - ${order.client.companyName} ( ${fullYear(order.startAt)} - ${fullYear(order.endAt)} )`;
            }
        },


        searchOrder: debounce(function (id) {
            if (id) {
                this.isOrderLoading = true;

                getOrder(id, '?_groups[]=order_list_read').then(response => {
                    this.options.orders = [response.data];
                    this.isOrderLoading = false;

                }, error => {
                    this.isOrderLoading = false;
                    notifyError('Konnte die Bestellungen nicht bekommen! Server Error!');
                });
            }
        }, 400),

    searchPredefinedRouteDetail: debounce(function (name) {
        if (name) {
            this.isPredefinedRouteDetailLoading = true;

            getPredefinedRoutesList('?routeDescription=' + name).then(response => {
                this.options.predefinedRouteDetails = response.data;
                this.isPredefinedRouteDetailLoading = false;

                }, error => {
                    this.isPredefinedRouteDetailLoading = false;
                    notifyError('Routen konnten nicht geladen werden! Server Error!');
                });
        }
    }, 400),

    searchOrderConcept: debounce(function (name) {
        if (name) {
            this.isOrderConceptLoading = true;

            getOrderConcepts('?name=' + name).then(response => {
                this.options.orderConcepts = response.data;
                this.isOrderConceptLoading = false;

                }, error => {
                    this.isOrderConceptLoading = false;
                    notifyError('Konzepte konnten nicht geladen werden! Server Error!');
                });
        }
    }, 400),

    searchDayConcept: debounce(function (name) {
        if (name) {
            this.isDayConceptLoading = true;

                getDayConcepts({name: name}).then(response => {
                    this.options.dayConcepts = response.data;
                    this.isDayConceptLoading = false;

                }, error => {
                    this.isDayConceptLoading = false;
                    notifyError('Konzepte konnten nicht geladen werden! Server Error!');
                });
        }
    }, 400),


    searchClient: debounce(function (companyName) {
        if (companyName) {
            this.isClientLoading = true;

            getClients('?companyName=' + companyName).then(response => {
                this.options.clients = response.data;
                this.isClientLoading = false;

                }, error => {
                    this.isClientLoading = false;
                    notifyError('Couldn\'t get the Clients! Server Error!');
                });
        }
    }, 400),


    searchProvider: debounce(function (name) {
        if (name) {
            this.isProviderLoading = true;

            getProviders('?name=' + name).then(response => {
                this.options.providers = response.data;
                this.isProviderLoading = false;

                }, error => {
                    this.isProviderLoading = false;
                    notifyError('Couldn\'t get the Providers! Server Error!');
                });
        }
    }, 400),


    searchAgency: debounce(function (name) {
        if (name) {
            this.isAgencyLoading = true;

            getAgencies('?name=' + name).then(response => {
                this.options.agencies = response.data;
                this.isAgencyLoading = false;

                }, error => {
                    this.isAgencyLoading = false;
                    notifyError('Couldn\'t get the Agencies! Server Error!');
                });
        }
    }, 400),

    searchOrganisation: debounce(function (name) {
        if (name) {
            this.isOrganisationLoading = true;

            getOrganisations({
                name: name,
                }).then(response => {
                    this.options.organisations = response.data;
                    this.isOrganisationLoading = false;

                }, error => {
                    this.isOrganisationLoading = false;
                    notifyError('Couldn\'t get the Organisations! Server Error!');
                });
        }
    }, 400),

    searchBank: debounce(function (name) {
        if (name) {
                this.isBankLoading = true;

            getBanks({
                name: name,
                }).then(response => {
                    this.options.banks = response.data;
                    this.isBankLoading = false;

                }, error => {
                    this.isBankLoading = false;
                    notifyError('Couldn\'t get the Banks! Server Error!');
                });
        }
    }, 400),
    },
    };
