<template>
    <iframe class="preview" style="height: 790px" id="iframeID" :src="`https://docs.google.com/gview?url=${this.previewURL}&embedded=true`" ref="iframe" :key="key" @load="hasLoaded" />
</template>

<script>

import {getPreviewFilePath} from "@utilities/functions";

export default {
    props: ['value'], // URL

    data: function() {
        return {
            loaded: false,
            key: + new Date(),
            lastRefresh: new Date(),
            refreshed: 0
        }
    },

    mounted: function() {
        this.intervalid1 = setInterval(this.refreshIFrame, 3000);
    },

    beforeDestroy() {
        clearInterval(this.intervalid1);
    },

    methods: {
        refreshIFrame() {
            const currentDate = new Date;
            if(!this.loaded && this.value && currentDate >= this.lastRefresh){
                const iframe = document.getElementById('iframeID');
                iframe.src = iframe.src;
                this.refreshed++;
                this.lastRefresh = new Date();
                this.lastRefresh.setSeconds(this.lastRefresh.getSeconds() + this.refreshed/2);
            }
        },

        hasLoaded() {
            this.loaded = true;
            clearInterval(this.intervalid1);
        }
    },

    computed: {
        previewURL: function () {
            return getPreviewFilePath(this.value);
        }
    }
}
</script>
