import { fullYear } from '@utilities/functions';
import prices from './prices';
import toggle from './toggle';


export default {
    mixins: [prices, toggle],

    filters: {
        fullYear
    }
};
