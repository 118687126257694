var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.editable && !_vm.isMultiple
    ? _c(
        "div",
        {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip.top",
              value: {
                content: _vm.userFullName,
                delay: { show: 1000, hide: 0 },
              },
              expression:
                "{content: userFullName, delay: {show: 1000, hide: 0}}",
              modifiers: { top: true },
            },
          ],
          staticClass: "user",
          class: { "is-small": _vm.small },
        },
        [
          !_vm.isMultiple
            ? _c("div", {
                staticClass: "user__avatar",
                style: _vm.userStyle(_vm.user),
              })
            : _vm._e(),
        ]
      )
    : _vm.multipleInTable
    ? _c(
        "div",
        { staticClass: "users" },
        _vm._l(_vm.user, function (u, index) {
          return _c(
            "div",
            {
              key: u.id,
              staticClass: "user is-small",
              style: { zIndex: _vm.user.length - index },
            },
            [
              _c("div", {
                staticClass: "user__avatar",
                style: _vm.userStyle(u),
              }),
            ]
          )
        }),
        0
      )
    : _c(
        "popover",
        {
          ref: "user",
          staticClass: "userPopover",
          attrs: { delay: { show: 0, hide: 0 } },
          on: { show: _vm.onOpen },
        },
        [
          !_vm.isMultiple
            ? _c(
                "div",
                {
                  staticClass: "user is-editable",
                  class: { "is-small": _vm.small },
                },
                [
                  _c("div", {
                    staticClass: "user__avatar",
                    style: _vm.userStyle(_vm.user),
                  }),
                  _vm._v(" "),
                  _c("i", { staticClass: "material-icons is-pointer" }, [
                    _vm._v("edit"),
                  ]),
                ]
              )
            : [
                _c(
                  "div",
                  { staticClass: "users" },
                  [
                    _vm.user.length > 0
                      ? _vm._l(_vm.user, function (u, index) {
                          return _c(
                            "div",
                            {
                              key: u.id ? u.id : u.username,
                              staticClass: "user",
                              class: {
                                "is-small": _vm.small,
                                "is-editable": !index,
                              },
                              style: { zIndex: _vm.user.length - index },
                            },
                            [
                              _c("div", {
                                staticClass: "user__avatar",
                                style: _vm.userStyle(u),
                              }),
                              _vm._v(" "),
                              !index
                                ? _c(
                                    "i",
                                    {
                                      staticClass: "material-icons is-pointer",
                                    },
                                    [_vm._v("edit")]
                                  )
                                : _vm._e(),
                            ]
                          )
                        })
                      : _c(
                          "div",
                          {
                            staticClass: "user is-editable",
                            class: { "is-small": _vm.small },
                          },
                          [
                            _c("div", {
                              staticClass: "user__avatar",
                              style: _vm.userStyle(null),
                            }),
                            _vm._v(" "),
                            _c(
                              "i",
                              { staticClass: "material-icons is-pointer" },
                              [_vm._v("edit")]
                            ),
                          ]
                        ),
                  ],
                  2
                ),
              ],
          _vm._v(" "),
          _c(
            "template",
            { slot: "popover" },
            [
              _c(
                "multiselect",
                {
                  attrs: {
                    "track-by": "id",
                    "custom-label": _vm.userLabel,
                    options: _vm.users,
                    loading: _vm.isLoading,
                    "internal-search": false,
                    "allow-empty": false,
                    multiple: _vm.isMultiple,
                  },
                  on: { "search-change": _vm.searchUsers, input: _vm.onInput },
                  model: {
                    value: _vm.userSelect,
                    callback: function ($$v) {
                      _vm.userSelect = $$v
                    },
                    expression: "userSelect",
                  },
                },
                [_c("span", { attrs: { slot: "caret" }, slot: "caret" })]
              ),
              _vm._v(" "),
              _vm.isMultiple && _vm.quick
                ? _c("div", { staticClass: "buttons level m-t" }, [
                    _c(
                      "button",
                      {
                        staticClass: "button",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            $event.stopPropagation()
                            return _vm.selectAllUsers.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("Alle")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "button is-success",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            $event.stopPropagation()
                            return _vm.selectCurrentUser.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("Nur ich")]
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }