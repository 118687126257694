var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.previewURL
    ? _c("loading")
    : _c("iframe", {
        key: _vm.key,
        ref: "iframe",
        staticStyle: { height: "790px" },
        attrs: { id: "iframeID", src: _vm.previewURL },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }