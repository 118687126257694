<template>
    <div>
        <div class="email-attachments">



            <input class="is-hidden" type="file" ref="fileUpload" v-on:input="addFiles" multiple>
            <button class="button is-gray" v-on:click="$refs.fileUpload.click()" style="margin-bottom: 5px">Anhänge vom Compuer hochladen</button>

            <button
                class="button is-gray"
                style="margin-bottom: 5px"
                v-on:click="isModalVisible = true">Anhänge aus dem Vorgang hochladen</button>


            <transition name="fade">
                <div class="attachments-modal" v-if="isModalVisible">
                    <div class="panel">
                        <div class="panel-heading">Anhänge</div>

                        <div class="panel-block">
                            <template v-if="!isLoading.documents">
                                <button
                                    v-for="document in documents"
                                    v-bind:key="document.id"
                                    v-bind:class="{ 'is-selected': isSelected(document) }"
                                    v-on:click="toggleAttachment(document)"
                                >
                                    <i class="material-icons">check_circle</i>
                                    [{{fileType(document)}}] {{ document.name }} {{ formatDate(document.createdAt) }}
                                </button>
                                <div v-if="documents.length === 0" class="is-empty">Es gibt keine Dokumente</div>
                            </template>
                            <loading v-else></loading>
                        </div>

                        <footer style="display: block">
                            <div class="level">
                                <button class="button" v-on:click="isModalVisible = false">Keine weiteren Anhänge</button>
                                <!--                            <input class="is-hidden" type="file" ref="fileUpload" v-on:input="addFiles" multiple>
                                                            <button class="button is-primary" v-on:click="$refs.fileUpload.click()">Anhänge vom Compuer hochladen</button>-->
                            </div>
                        </footer>

                    </div>
                </div>
            </transition>
        </div>
        <div
            v-for="(file, index) in localAttachments"
            class="button"
            v-on:click="previewDocument(file)"
            style="margin-bottom: 5px"
            v-bind:title="file.name"
        >
            <i aria-hidden="true" class="fa fa-file"></i> {{ file.name }}
            <button class="btn-delete" title="Löschen" v-on:click="localAttachments.splice(index, 1)"><i class="material-icons">close</i></button>
            <preview  v-model="previewURL" />

        </div>
    </div>

</template>



<script>

import moment from 'moment';
import Loading from '@components/Loading';
import { getDocuments } from '@api';
import { notifyError } from '@components/Notification';
import Preview       from '@components/documents/Preview';

export default {
    props: {
        ordersSelected: {},
        value: {}
    },


    data: function () {
        return {
            localAttachments: this.value,
            documents: [],
            previewURL: null,

            isModalVisible: false,
            isLoading: {
                documents: false,
                input: false
            }
        }
    },


    methods: {
        previewDocument: function(document) {
            if(document.id) {
                this.previewURL = document;
            }
        },
        addFiles: function (event) {
            this.localAttachments.push(...[...event.target.files]);
        },
        formatDate: function(date) {
            return date ? moment(date, 'DD.MM.YYYY hh:mm:ss').format('DD.MM.YYYY hh:mm') : '';
        },

        fileType: function (document) {
            return document.path ? `.${document.path.split('.').pop()}` : '';
        },

        toggleAttachment: function (document) {
            const index = this.localAttachments.findIndex(item => item.id === document.id);

            if (index === -1) {
                this.localAttachments.push(document);

            } else {
                this.localAttachments.splice(index, 1);
            }
        },


        isSelected: function (document) {
            return this.localAttachments.find(item => item.id === document.id);
        }
    },


    watch: {
        localAttachments: function () { this.$emit('input', this.localAttachments); },


        isModalVisible: function () {
            if (this.isModalVisible) {
                let apiQuery = '';

                if (this.ordersSelected.length > 0) {
                    apiQuery += '?links.order.id[]=' + this.ordersSelected.map(order => order.id).join('&links.order.id[]=');
                }

                this.isLoading.documents = true;
                // TODO - after talk with Alex about multiple order.ids
                getDocuments(apiQuery).then(response => {
                        this.documents = response.data;
                    }, error => { notifyError('Couldn\'t retrieve concepts! Server Error!'); })
                    .then(() => { this.isLoading.documents = false; });
            }
        }
    },


    components: {
        Preview,
        Loading
    }
}
</script>
