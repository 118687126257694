var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("iframe", {
    key: _vm.key,
    ref: "iframe",
    staticClass: "preview",
    staticStyle: { height: "790px" },
    attrs: {
      id: "iframeID",
      src: `https://docs.google.com/gview?url=${this.previewURL}&embedded=true`,
    },
    on: { load: _vm.hasLoaded },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }