<template>
    <modal
        v-if="this.value"
        visible
        @closeModal="$emit('input', null)"
    >
        <p slot="header">Vorschau</p>
        <div slot="content">
            <microsoft-frame v-if="value.cloudStorage" :value="value" />
            <my-frame v-else :value="value"/>
        </div>
    </modal>
</template>

<script>
import Modal from '@components/Modal';
import MyFrame from '@components/documents/Frame';
import MicrosoftFrame from '@components/documents/FrameMicrosoft';

export default {
    props: {
        value: { required: true},
    },



    components: {
        Modal,
        MyFrame,
        MicrosoftFrame
    },
}
</script>
