import ListItem from '@components/ListItem';
import { fullYear, priceView } from '@utilities/functions';


export default {
    props: {
        item: {
            type: Object,
            required: true
        }
    },


    computed: {
        placeholder: function () {
            return this.item.placeholder;
        },

        date: function () {
            if (this.placeholder) {
                let { startAt, endAt } = this.placeholder;
                startAt = fullYear(startAt);
                endAt = fullYear(endAt);

                if (startAt !== endAt) {
                    return `${startAt} - ${endAt}`;
                } else {
                    return startAt;
                }
            }

            return false;
        },
    },


    components: {
        ListItem
    },


    filters: {
        priceView
    }
};
