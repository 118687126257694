<template>
    <modal
        classes="new-email"
        v-bind:visible="isModalVisible"
        v-on:closeModal="$emit('closeModal')"
    >
        <template slot="header">
            <div class="name" v-if="!action">NEUE EMAIL</div>
            <div class="name" v-else-if="action === 'reply'">Antworten</div>
            <div class="name" v-else-if="action === 'replyAll'">Allen Antworten</div>
            <div class="name" v-else-if="action === 'forward'">Weiterleiten</div>

            <div class="buttons has-addons">
                <button
                    class="button is-large"
                    v-bind:class="{'is-primary': !hasClients}"
                    v-on:click="hasClients = false"
                ><i class="material-icons">email</i> Standardmitteilung</button>

                <button
                    class="button is-large"
                    v-bind:class="{'is-primary': hasClients}"
                    v-on:click="hasClients = true"
                ><i class="material-icons">dehaze</i> Leistungsbezogene Mitteilung</button>
            </div>
        </template>


        <template slot="content">
            <orders-select
                v-bind:hasClients        = "hasClients"
                v-bind:preselectedOrders = "preselectedOrders"
                v-bind:preselectedClient = "preselectedRequest"
                v-on:updateOrders = "value => ordersSelected = value"
                :params="{_groups: ['client_read']}"
                v-on:update       = "handleUpdate"
            ></orders-select>


            <email-form
                v-bind:ordersSelected     = "ordersSelected"
                v-bind:hasClients         = "hasClients"
                v-bind:recipient          = "recipient"
                v-bind:emailSelected      = "emailAction ? emailSelected : null"
                v-bind:preselectedContact = "preselectedContact"
                v-bind:select-template    = "selectTemplate"
                v-bind:attach-document    = "attachDocument"
                v-on:closeModal = "refresh => $emit('closeModal', refresh)"
                ref="form"
            ></email-form>
        </template>
    </modal>
</template>



<script>
import axios    from 'axios';
import uniq     from 'lodash/uniq';
import Modal    from '@components/Modal';
import { getClients, getOrders }      from '@api';
import { notifyError, notifySuccess } from '@components/Notification';
import store        from '@components/emails/store';
import EmailForm    from './EmailForm';
import OrdersSelect from './OrdersSelect';


export default {
    store: store,


    props: {
        preselectedOrder:   { default: null },
        preselectedContact: { default: null },
        preselectedRequest: { default: null },
        selectTemplate:     { default: null},
        isModalVisible:     { type: Boolean, required: true },
        attachDocument:     { type: Object, default: null}
    },


    computed: {
        emailAction: function () {
            return this.action === 'reply' ||
                   this.action === 'replyAll' ||
                   this.action === 'forward';
        },


        preselectedOrders: function () {

            if (this.emailSelected && this.emailAction) {
                // Getting the ids
                let ids = this.emailSelected.links.map(link => link.order ? link.order.id : null);
                // Removing the nulls
                ids = ids.filter(id => id !== null);
                // Only unique (as we may have multiple links from the same order)
                ids = uniq(ids);

                return ids;

            } else {
                if(this.preselectedOrder){
                    return this.preselectedOrder.length ? this.preselectedOrder : [this.preselectedOrder]
                }
                return [];
            }
        },


        clients:       function () { return this.$store.state.clients; },
        action:        function () { return this.$store.state.action; },
        emailSelected: function () { return this.$store.state.emailsSelected[this.$store.state.emailsSelected.direction][0]; }
    },

    methods: {
        handleUpdate: function(itemSelected) {
            this.recipient = itemSelected;

            if (this.$refs.form) {
                this.$refs.form.selectAllRequests()
            }
        }
    },


    data: function () {
        return {
            ordersSelected: [],
            recipient: null,

            providers: null,
            customers: null,

            hasClients: false
        }
    },


    created: function () {
        if (this.preselectedRequest) {
            this.$store.commit('updatePreselectedClient', {
                    client: this.preselectedRequest.agency ? this.preselectedRequest.agency :
                            this.preselectedRequest.hotel   !== undefined ? this.preselectedRequest.hotel :
                            this.preselectedRequest.ferry   !== undefined ? this.preselectedRequest.ferry :
                            this.preselectedRequest.airline !== undefined ? this.preselectedRequest.airline :
                            this.preselectedRequest.otherService.provider,
                    requests: [this.preselectedRequest]
                });
            this.hasClients = true;
        }
    },


    components: {
        EmailForm,
        Modal,
        OrdersSelect
    }
}
</script>
