import weTours from './weTours/EmailBody';
import buspaket from './buspaket/EmailBody';

export default function (organisation = '', body = '', phone = '', email = null) {
    if(organisation === 'we tours'){
        return weTours(body, phone, email);
    } else {
        return buspaket(body, phone, email);
    }
}
